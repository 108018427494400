import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import { Card, CardBody, FormGroup, Input, Label, Button} from 'reactstrap';
import Select from 'react-select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import Api from '../api';
import {useDispatch, useSelector} from 'react-redux';
import {setExchanges} from '../redux/actions/exchagesActions';
import {setKeysSets} from '../redux/actions/keysSetsActions';
import {setStrategiesTypes} from "../redux/actions/strategyActions";
import {logOut} from "../hooks";
import {reactSelectStyles} from "../variables/react-select-styles";
import {setError, setSuccess} from "../redux/actions/alertActions";
import {reactSelectLightStyles} from "../variables/react-select-light-styles";

const DealForm = ({buy, state}) => {
  const api = new Api();
  const user = JSON.parse(localStorage.getItem('user'))
  const cid = user?.cid
  const [balance, setBalance] = useState([])
  const dispatch = useDispatch();
  const exchangesData = useSelector(state => state.exchanges);
  const keysSetsData = useSelector(state => state.keysSets);
  const [tradingPairs, setTradingPairs] = useState([]);
  const [formData, setFormData] = useState({
    exchange: null,
    pair: null,
    price: '',
    amount: '',
    side: buy ? 'BUY' : 'SELL',
    keySetID: null,
  });
  const resetFormData = () => {
    setFormData({
      exchange: null,
      pair: null,
      price: '',
      amount: '',
      side: buy ? 'BUY' : 'SELL',
      keySetID: null,
    });
  };
  const [formErrors, setFormErrors] = useState({})
  const [dealId, setDealId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    intervalIdRef.current = intervalId;
  }, [intervalId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!cid) {
        logOut();
        return;
      }

      try {
        const exchangesRes = await api.getExchanges();
        if (exchangesRes.exchanges) {
          dispatch(setExchanges(exchangesRes.exchanges));
        }

        const keysSetsRes = await api.getCompanyKeys(cid);
        if (keysSetsRes.KeysSets) {
          dispatch(setKeysSets(keysSetsRes.KeysSets));
        }

        if(state){
          // console.log(state)
          const findExchange = _.find(exchangesRes.exchanges, exchange => exchange.name === state.exchange)
          const findKey = _.find(keysSetsRes.KeysSets, key => key.id === _.first(state.key_sets))
          if(findExchange && findKey){
            await getPairs(findExchange.name)
            setFormData({
              ...formData,
              exchange: {
                value: findExchange.eid,
                label: findExchange.name
              },
              pair: {
                label: `${state.pair.base}-${state.pair.quote}`,
                value: `${state.pair.base}-${state.pair.quote}`
              },
              keySetID: {
                value: findKey.id,
                label: findKey.name,
                exchange: findKey.exchange
              }
            })
          }
        }
        if(state.pair){

        }

      } catch (error) {
        console.error('Fetching data error', error);
      }
    };

    fetchData();
  }, [cid]);
  useEffect(() => {
    if (formData.keySetID?.value) {
      api.getBalance(formData.keySetID?.value)
        .then(res => {
          if (res) {
            setBalance(res)
            // setBalance({
            //   "APE": 0,
            //   "CAVA": 0,
            //   "ETH": 0,
            //   "HEX": 0,
            //   "KCS": 0,
            //   "PEPE": 0,
            //   "PoS": 0,
            //   "SOAPS": 0,
            //   "UBCDR": 0,
            //   "UBIX": 0,
            //   "UBPAY": 0,
            //   "UBSN": 0,
            //   "UBWF": 0,
            //   "UBX": 111,
            //   "UBX4T": 0,
            //   "UBXE": 0,
            //   "UBXM": 0,
            //   "USDT": 222
            // })
          }
        })
        .catch((e) => {
          console.error('getBalance error', e)
        })
    }
  }, [formData.keySetID]);


  const handleChange = async (name, value) => {
    console.log(name, value)
    setFormData({...formData, [name]: value});
    setFormErrors({...formErrors, [name]: false})

  };

  const handleExchangeChange = async (selectedOption) => {
    setFormData({...formData, exchange: selectedOption, pair: null, keySetID: null});
    setFormErrors({...formErrors, 'exchange': false})
    await getPairs(selectedOption.label)
  };

  const getPairs = async (label) => {
    try {
      const res = await api.getPairs(label);
      if (res.pairs) {
        setTradingPairs(res.pairs.map(pair => ({value: pair, label: pair})));
      }
    } catch (e) {
      console.error('getPairs error', e);
    }
  }

  const filteredKeyOptions = keysSetsData?.keysSets
    .filter(keySet => formData.exchange && keySet.exchange === formData.exchange.label)
    .map(keySet => ({
      value: keySet.id,
      label: keySet.name,
      exchange: keySet.exchange,
    })) || [];


  const validateAndPrepareData = () => {
    let errors = {};
    let isValid = true;
    const {exchange, pair, price, amount, side, keySetID} = formData;
    let preparedData = {};

    // Валидация exchange
    if (!exchange) {
      isValid = false;
      errors.exchange = 'Exchange is required';
    }

    // Валидация pair
    if (!pair) {
      isValid = false;
      errors.pair = 'Pair is required';
    }

    // Валидация price
    if (!price || isNaN(parseFloat(price)) || parseFloat(price) <= 0) {
      isValid = false;
      errors.price = 'Price must be a positive number';
    }

    // Валидация amount
    if (!amount || isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
      isValid = false;
      errors.amount = 'Amount must be a positive number';
    } else {
      const curBalance = balance?.[pair?.value?.split('-')?.[side === 'BUY' ? 1 : 0]] || 0;

      if (side === 'BUY' && parseFloat(amount) > parseFloat(curBalance) / parseFloat(price)) {
        isValid = false;
        errors.amount = `Amount cannot be greater than balance / price: ${formatNumberIfExponential(curBalance / price)} ${pair?.value?.split('-')?.[side === 'BUY' ? 1 : 0]}`;
      } else if (side === 'SELL' && parseFloat(amount) > parseFloat(curBalance)) {
        isValid = false;
        errors.amount = `Amount cannot be greater than balance: ${formatNumberIfExponential(curBalance)} ${pair?.value?.split('-')?.[side === 'BUY' ? 1 : 0]}`;
      }
    }

    // Валидация keySetID
    if (!keySetID) {
      isValid = false;
      errors.keySetID = 'Key Set ID is required';
    }

    if (isValid) {
      // Подготовка данных для отправки
      preparedData = {
        pair: {
          base: pair.value.split('-')[0],
          quote: pair.value.split('-')[1],
        },
        price: parseFloat(price),
        amount: parseFloat(amount),
        side: side,
        keyset_id: keySetID.value,
      };
    }

    setFormErrors(errors)


    return {isValid, errors, preparedData};
  };


  const handleSubmit = e => {
    e.preventDefault();
    const {isValid, errors, preparedData} = validateAndPrepareData();

    if (isValid) {
      setIsProcessing(true); // Заблокировать форму
      api.createDeal(preparedData)
        .then(res => {
          setDealId(res.id); // Сохранить ID сделки
          // Начать цикл обновления статуса сделки
          updateDealStatus(res.id)
          const newIntervalId = setInterval(() => updateDealStatus(res.id), 2000);
          setIntervalId(newIntervalId);
        })
        .catch(e => {
          console.error('createDeal Error: ', e);
          setIsProcessing(false); // Разблокировать форму в случае ошибки
        });
    }
  };

  const updateDealStatus = (id) => {
    api.updateDealStatus(id)
      .then(res => {
        if (res.status === 'DONE' || res.status === 'CANCEL') {
          dispatch(setSuccess(`The strategy named ${formData.name} was successfully updated`))
          clearInterval(intervalIdRef.current); // Очищаем интервал, используя ref
          setIntervalId(null);
          setIsProcessing(false); // Разблокировать форму
          setDealId(null); // Сбросить ID сделки
          resetFormData()
          if (res.status === 'DONE') {
            dispatch(setSuccess(`The deal with ID ${id} was successfully completed`));
          }
          if (res.status === 'CANCEL') {
            dispatch(setError(`The deal with ID ${id} was canceled`));
          }
        }
      })
      .catch(e => console.error('updateDealStatus Error: ', e));
  };

  // Функция для отправки запроса на отмену сделки
  const handleCancel = () => {
    if (dealId) {
      api.cancelDeal(dealId)
        .then(res => {
          if (res.status === 'DONE') {
            dispatch(setSuccess(`The deal with ID ${dealId} was successfully canceled`));
          } else {
            dispatch(setError(`The deal with ID ${dealId} has error: ` + res.status));

          }
          // Обработка успешной отмены сделки...
          clearInterval(intervalId); // Останавливаем интервал
          setIntervalId(null);
          setIsProcessing(false); // Разблокировать форму
          setDealId(null); // Сбросить ID сделки
          resetFormData()
        })
        .catch(e => console.error('cancelDeal Error: ', e));
    }
  };

  const exchangeOptions = exchangesData?.exchanges?.map(exchange => ({
    value: exchange.eid,
    label: exchange.name,
  })) || [];

  const keyOptions = keysSetsData?.keysSets.map(keySet => ({
    value: keySet.id,
    label: keySet.name,
    exchange: keySet.exchange,
  })) || [];

  const formatNumberIfExponential = (num) => {
    if (num && num.toString().includes('e')) {
      return Number(num).toFixed(20).replace(/\.?0+$/, ''); // Округляем с большой точностью и удаляем незначащие нули
    }
    return num;
  }

  return (

    <Card>
      <CardBody>
        <h2>{'Create Deal'}</h2>
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label for='exchange'>Exchange<span className='text-danger'>*</span></Label>
            <Select
              options={exchangeOptions}
              value={formData.exchange}
              onChange={option => handleExchangeChange(option)}
              styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
              placeholder='Select Exchange'
              className='mb-3'
              isDisabled={isProcessing}
            />
            {formErrors.exchange && <p className='text-danger'>{formErrors.exchange}</p>}
          </FormGroup>

          <FormGroup>
            <Label for='pair'>Pair<span className='text-danger'>*</span></Label>
            <Select
              options={_.sortBy(tradingPairs, 'label')}
              value={formData.pair}
              onChange={option => handleChange('pair', option)}
              styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
              placeholder='Select Pair'
              className='mb-3'
              isDisabled={isProcessing}

            />
            {formErrors.pair && <p className='text-danger'>{formErrors.pair}</p>}

          </FormGroup>

          <FormGroup>
            <Label for='keySetID'>Key Set ID<span className='text-danger'>*</span></Label>
            <Select
              options={filteredKeyOptions}
              value={formData.keySetID}
              onChange={option => handleChange('keySetID', option)}
              styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
              placeholder='Select Key Set'
              className='mb-3'
              isDisabled={!formData.exchange || isProcessing}
            />
            {formErrors.keySetID && <p className='text-danger'>{formErrors.keySetID}</p>}

          </FormGroup>

          <FormGroup>
            <Label>Side</Label>
            <div className='toggle-switch d-flex align-items-center'>
                    <span className={`toggle-icon mr-2 ${formData.side === 'SELL' ? 'text-success' : ''}`}>
                      <i className="fa-solid fa-dollar-sign"/> SELL
                    </span>
              <Toggle
                id='side'
                checked={formData.side === 'BUY'}
                onChange={() => handleChange('side', formData.side === 'BUY' ? 'SELL' : 'BUY')}
                icons={false} // Disable built-in icons
                disabled={isProcessing}
              />
              <span className={`toggle-icon ml-2 ${formData.side === 'BUY' ? 'text-danger' : ''}`}>
                      BUY <i className="fa-solid fa-cart-shopping"/>
                    </span>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for='price'>Price</Label>
            <Input
              type='number'
              step='0.01'
              id='price'
              value={formData.price}
              onChange={e => handleChange('price', e.target.value)}
              placeholder='Enter price'
              disabled={isProcessing}
            />
            {formErrors.price && <p className='text-danger'>{formErrors.price}</p>}

          </FormGroup>

          <FormGroup>
            <Label for='amount'>
              Amount
              (Balance: {formatNumberIfExponential(balance?.[formData?.pair?.value?.split('-')?.[formData.side === 'BUY' ? 1 : 0]]) || 0} {formData?.pair?.value?.split('-')?.[formData.side === 'BUY' ? 1 : 0]})

            </Label>
            <Input
              type='number'
              step='0.01'
              id='amount'
              value={formData.amount}
              onChange={e => handleChange('amount', e.target.value)}
              placeholder='Enter amount'
              disabled={isProcessing}
            />
            {formErrors.amount && <p className='text-danger'>{formErrors.amount}</p>}

          </FormGroup>


          <Button color='primary' type='submit' className={'mt-3'} disabled={isProcessing}>
            {isProcessing ? 'Processing...' : 'Create Deal'}
          </Button>
          {isProcessing && (
            <Button color='secondary' className={'mt-3 ml-3'} onClick={handleCancel} disabled={!dealId}>
              Cancel Deal
            </Button>
          )}
        </form>
      </CardBody>
    </Card>
  );
};

export default DealForm;
