// UnauthenticatedLayout.js
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import AlertComponent from '../../components/Alert'

const UnauthenticatedLayout = ({ children }) => {
  return (
    <div className='wrapper'>
      <div className='main-panel'>
        <Container fluid>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default UnauthenticatedLayout
