/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
// nodejs library to set properties for components
import { PropTypes } from 'prop-types'
import _ from 'lodash'

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from 'reactstrap'
import { BackgroundColorContext, backgroundColors } from 'contexts/BackgroundColorContext'

var ps

const Sidebar = ({user, routes, logoText, toggleSidebar}) => {
  const location = useLocation()
  const sidebarRef = React.useRef(null)
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : ''
  }
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
    }
  })
  const linkOnClick = () => {
    document.documentElement.classList.remove('nav-open')
  }
  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className='sidebar' data={color}>
          <div className='sidebar-wrapper' ref={sidebarRef}>
            <div className='logo'>
              <span className='ml-3 simple-text logo-normal'>{logoText}</span>
            </div>
            <Nav>
              {_.map(routes, (prop, key) => {
                if (prop.redirect) return null
                if (!prop.showInSideBar) return null
                return (
                  <li
                    className={activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')}
                    key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      className='nav-link'
                      onClick={toggleSidebar}>
                      <i className={prop.icon} />
                      <p className='lead text-white'>{prop.name}</p>
                    </NavLink>
                  </li>
                )
              })}
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  )
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
}

export default Sidebar
