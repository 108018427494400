// roles.js
const getUser = () => JSON.parse(localStorage.getItem('user'))

export const isAdmin = () => {
  const user = getUser()
  if(!user) return true
  return user && user.grp === 'admin'
}

export const isUser = () => {
  const user = getUser()
  if(!user) return true
  return user && user.grp === 'user'
}

export const isWatchdog = () => {
  const user = getUser()
  if(!user) return true
  return user && user.grp === 'watchdog'
}
