export const reactSelectStyles = disabled => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: disabled ? '#1d253b' : 'transparent',
    color: disabled ? '#344675' : 'rgba(255, 255, 255, 0.8)',
    borderColor: state.isFocused ? '#e14eca' : '#2b3553',
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#e14eca' : '#2b3553',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2675f6' : disabled ? '#1d253b' : 'transparent',
    color: state.isSelected ? 'rgba(255, 255, 255, 0.8)' : disabled ? '#344675' : 'black',
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#2675f6',
    color: 'rgba(255, 255, 255, 0.8)',
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.8)',
  }),
  input: provided => ({
    ...provided,
    color: disabled ? '#344675' : 'rgba(255, 255, 255, 0.8)',
    paddingLeft: '8px',
  }),
  singleValue: provided => ({
    ...provided,
    color: disabled ? '#344675' : 'rgba(255, 255, 255, 0.8)',
    paddingLeft: '8px',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgb(108, 117, 124)',
    paddingLeft: '8px',
  }),
})
