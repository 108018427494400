import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Table, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { useTranslation } from 'react-i18next'
import fakeKeys from '../fakeData/apiKeys.json'
import Select from 'react-select'
import { reactSelectStyles } from '../variables/react-select-styles'
import { useDispatch, useSelector } from 'react-redux'
import Api from '../api'
import { setExchanges } from '../redux/actions/exchagesActions'
import { setKeysSets } from '../redux/actions/keysSetsActions'
import { logOut } from '../hooks'
import { isAdmin } from '../roles'
import ConfirmModal from '../components/ConfirmModal'
import {reactSelectLightStyles} from "../variables/react-select-light-styles";

const ApiKeys = () => {
  const navigate = useNavigate()
  if (!isAdmin()) {
    navigate('/access-denied')
  }
  const api = new Api()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const keysSets = useSelector(state => state.keysSets)
  const [apiKeys, setApiKeys] = useState([])
  const [searchText, setSearchText] = useState('')
  const [showActiveOnly, setShowActiveOnly] = useState(false)
  const [exchangeFilter, setExchangeFilter] = useState([])
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [apiKeyToDelete, setApiKeyToDelete] = useState(null)

  const cid = JSON.parse(localStorage.getItem('user'))?.cid

  useEffect(() => {
    if (cid) {
      api
        .getCompanyKeys(cid)
        .then(res => {
          if (res.KeysSets) {
            dispatch(setKeysSets(res.KeysSets))
          }
        })
        .catch(e => console.error('getCompanyKeys error ', e))
    } else {
      logOut()
    }
  }, [])

  const handleEditApiKey = id => {
    navigate(`edit/${id}`)
  }

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen)
  }

  const handleDeleteApiKey = async key => {
    // Сохраните ключ, который нужно удалить, в состоянии
    setApiKeyToDelete(key)
    // Откройте модальное окно для подтверждения
    toggleConfirmModal()
  }

  const confirmDeleteApiKey = async () => {
    // Закройте модальное окно подтверждения
    toggleConfirmModal()

    // Удалите API ключ, используя apiKeyToDelete
    api
      .deleteApiKey(apiKeyToDelete)
      .then(res => {
        if (res.success) {
          api
            .getCompanyKeys(cid)
            .then(res => {
              if (res.KeysSets) {
                dispatch(setKeysSets(res.KeysSets))
              }
            })
            .catch(e => console.error('deleteApiKey error ', e))
        }
      })
      .catch(e => console.error(e))
  }

  const handleToggleChange = async key => {
    api
      .disableApiKey(key)
      .then(res => {
        if (res.success) {
          api
            .getCompanyKeys(cid)
            .then(res => {
              if (res.KeysSets) {
                dispatch(setKeysSets(res.KeysSets))
              }
            })
            .catch(e => console.error('disableApiKey error ', e))
        }
      })
      .catch(e => console.error(e))
  }

  let filteredApiKeys = []
  if (!_.size(keysSets)) {
    return
  } else {
    filteredApiKeys = _.filter(keysSets.keysSets, key => {
      const exchangeMatch =
        exchangeFilter.length === 0 || exchangeFilter.some(option => option.value === key.exchange)

      const matchesSearch = key?.name?.toLowerCase().includes(searchText?.toLowerCase())

      if (showActiveOnly) {
        return exchangeMatch && matchesSearch && !key.active
      } else {
        return exchangeMatch && matchesSearch
      }
    })
  }

  const exchangeOptions = _.uniq(_.map(keysSets.keysSets, 'exchange')).map(exchange => ({
    value: exchange,
    label: exchange,
  }))
  const handleExchangeFilterChange = selectedOptions => {
    setExchangeFilter(selectedOptions)
  }

  return (
    <>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <div className='d-flex justify-content-between align-items-end mb-3 fdcXS aieXS'>
              <h2 className={'tacXS'}>{t('apiKeys.title')}</h2>
              <Button
                className='btn-default'
                color='info'
                size='sm'
                onClick={() => {
                  navigate('/admin/apikeys/add')
                }}>
                {t('apiKeys.addApiKey')}
              </Button>
            </div>
            <div className='d-flex justify-content-between align-items-end mb-3 fdcXS btXS'>
              <div className='d-flex justify-content-start align-items-end mb-3 w-100 fdcXS'>
                {/* Фильтр по бирже */}
                <Select
                  isMulti
                  options={exchangeOptions}
                  value={exchangeFilter}
                  onChange={handleExchangeFilterChange}
                  placeholder={t('apiKeys.exchangePlaceholder')}
                  styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
                  className='mr-3 w100pXS selectFilters'
                />
                <div className='form-group mb-0 w-25 w100pXS'>
                  <Input
                    type='text'
                    className='form-control'
                    placeholder={t('apiKeys.searchPlaceholder')}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <label className='d-flex justify-content-between align-items-center flex-shrink-0 ml-3 ml-3XS'>
                <Toggle
                  checked={showActiveOnly}
                  onChange={() => setShowActiveOnly(!showActiveOnly)}
                  className='mr-2'
                />
                <span className='text-white'>{t('apiKeys.showExpired')}</span>
              </label>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th className='text-left' style={{ width: '25%' }}>
                    {t('apiKeys.table.name')}
                  </th>
                  <th className='text-center' style={{ width: '25%' }}>
                    {t('apiKeys.table.exchange')}
                  </th>
                  <th className='text-right' style={{ width: '25%' }}></th>
                </tr>
              </thead>
              <tbody>
                {_.sortBy(filteredApiKeys, 'id').map(key => (
                  <tr key={key.id}>
                    <td className='text-left'>{key.name}</td>
                    <td className='text-center'>{key.exchange}</td>
                    <td className='text-right d-flexXS'>
                      <div>
                        <Toggle
                          className='ml-1'
                          checked={key.active || false}
                          onChange={() => handleToggleChange(key)}
                        />
                      </div>
                      <div className='d-flex justify-content-end align-items-center d-flexXS'>
                        {key.active ? (
                          ''
                        ) : (
                          <span className='badge badge-danger' style={{ padding: '1em' }}>
                            {t('apiKeys.table.expired')}
                          </span>
                        )}
                        <Button
                          className='btn-icon mr-1 ml-1'
                          color='info'
                          size='sm'
                          onClick={() => handleEditApiKey(key.id)}>
                          <i className='fa fa-pencil'></i>
                        </Button>
                        <Button
                          className='btn-icon'
                          color='danger'
                          size='sm'
                          onClick={() => handleDeleteApiKey(key)}>
                          <i className='fa fa-trash'></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        isOpen={confirmModalOpen}
        toggle={toggleConfirmModal}
        onConfirm={confirmDeleteApiKey}
        text={`Are you sure you want to delete ${apiKeyToDelete?.name}?`}
      />
    </>
  )
}

export default ApiKeys
