import React, {useEffect, useMemo, useState} from 'react'
import {Button, Col, Row, Table} from 'reactstrap'
import _ from 'lodash'
import Chart from '../components/Chart'
import Select from 'react-select'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import {useNavigate} from 'react-router-dom'
import {reactSelectStyles} from '../variables/react-select-styles'
import {reactSelectLightStyles} from '../variables/react-select-light-styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  setStrategies,
  setStrategiesTypes,
  updateStrategiesRedux
} from '../redux/actions/strategyActions'
import strategiesFake from '../fakeData/strategies.json'
import {useTranslation} from 'react-i18next'
import WeekChart from '../components/WeekChart'
import Api from '../api'
import {logOut} from '../hooks'
import {isAdmin, isUser, isWatchdog} from '../roles'
import ConfirmModal from '../components/ConfirmModal'

const Strategy = () => {
  const api = new Api()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const strategies = useSelector(state => state.strategy.strategies)
  const strategiesTypes = useSelector(state => state.strategy.types)
  const lastUpdated = useSelector(state => state.strategy.lastUpdated)

  const navigate = useNavigate()
  // Состояния для фильтрации
  const [exchangeFilter, setExchangeFilter] = useState(() => {
    const storedExchangeFilter = sessionStorage.getItem('exchangeFilter');
    return storedExchangeFilter ? JSON.parse(storedExchangeFilter) : [];
  });
  const [pairFilter, setPairFilter] = useState(() => {
    const storedPairFilter = sessionStorage.getItem('pairFilter');
    return storedPairFilter ? JSON.parse(storedPairFilter) : [];
  });
  const [strategyTypeFilter, setStrategyTypeFilter] = useState(() => {
    const storedStrategyTypeFilter = sessionStorage.getItem('strategyTypeFilter');
    return storedStrategyTypeFilter ? JSON.parse(storedStrategyTypeFilter) : [];
  });
  const [strategyStates, setStrategyStates] = useState({})
  const [searchText, setSearchText] = useState(() => {
    const storedSearchText = sessionStorage.getItem('searchText');
    return storedSearchText || '';
  });
  const [showOnlyActive, setShowOnlyActive] = useState(() => {
    const storedShowOnlyActive = sessionStorage.getItem('showOnlyActive');
    return storedShowOnlyActive === 'true'; // Преобразуем строку в булевое значение
  });
  const [week_vol_data, setWeek_vol_data] = useState('') // Состояние для недельного графика
  const [backgroundColors, setBackgroundColors] = useState('') // Состояние для цветов графика

  // Преобразование данных для react-select
  const exchangeOptions = _.uniq(_.map(strategies, 'exchange')).map(exchange => ({
    value: exchange,
    label: exchange,
  }))
  const pairOptions = _.uniqBy(
    _.map(strategies, strategy => ({
      value: `${strategy.pair.base}-${strategy.pair.quote}`,
      label: `${strategy.pair.base}-${strategy.pair.quote}`,
    })),
    'value',
  )

  const strategyTypeOptions = _.uniq(_.map(strategies, 'strategy_type')).map(type => ({
    value: type,
    label: strategiesTypes.find(strategyType => strategyType.strategy_type_id === type)?.strategy_type_name,
  }))
  const uid = JSON.parse(localStorage.getItem('jwt'))?.uid
  const user = JSON.parse(localStorage.getItem('user'))
  const cid = user?.cid
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [strategyToDelete, setStrategyToDelete] = useState(null)

  // Функции для обновления фильтров
  const handleExchangeFilterChange = selectedOptions => {
    setExchangeFilter(selectedOptions)
    sessionStorage.setItem('exchangeFilter', JSON.stringify(selectedOptions))

  }

  const handlePairFilterChange = selectedOptions => {
    setPairFilter(selectedOptions)
    sessionStorage.setItem('pairFilter', JSON.stringify(selectedOptions))
  }

  const handleStrategyTypeFilterChange = selectedOptions => {
    setStrategyTypeFilter(selectedOptions)
    sessionStorage.setItem('strategyTypeFilter', JSON.stringify(selectedOptions))
  }

  // Функция для обновления текста поиска
  const handleSearchTextChange = e => {
    const newValue = e.target.value
    setSearchText(newValue)

    sessionStorage.setItem('searchText', newValue)
  }

  // Функция для обработки изменения состояния переключателя
  const handleOnlyActiveChange = () => {
    const newValue = !showOnlyActive;
    setShowOnlyActive(newValue);

    sessionStorage.setItem('showOnlyActive', newValue.toString());
  }

  const filteredStrategies = useMemo(() => {
    return _.filter(strategies, strategy => {
      // Применяем фильтры (если они заданы)
      const exchangeMatch =
        exchangeFilter.length === 0 ||
        exchangeFilter.some(option => option.value === strategy.exchange)
      const pairMatch =
        pairFilter.length === 0 ||
        pairFilter.some(option => {
          return option.value === `${strategy.pair.base}-${strategy.pair.quote}`;
        })

      const strategyTypeMatch =
        strategyTypeFilter.length === 0 ||
        strategyTypeFilter.some(option => {
          return option.value === strategy.strategy_type;
        })

      const searchMatch =
        !searchText || strategy.strategy_name.toLowerCase().includes(searchText.toLowerCase())

      if (showOnlyActive) {
        return exchangeMatch && pairMatch && strategyTypeMatch && strategy.active && searchMatch
      } else {
        return exchangeMatch && pairMatch && strategyTypeMatch && searchMatch
      }
    })
  }, [strategies, lastUpdated, exchangeFilter, pairFilter, strategyTypeFilter, searchText, showOnlyActive]);


  const handleToggleChange = strategyId => {
    setStrategyStates(prevState => ({
      ...prevState,
      [strategyId]: !prevState[strategyId],
    }))
    api
      .toggleStrategy(strategyId, !strategyStates[strategyId])
      .then(res => {
        if (res.success) {
          getUserStrategies()
        }
      })
      .catch(e => console.error('toggleStrategy error', e))
  }

  const getStrategiesTypes = (cid) => {
    api
      .getStrategiesTypes(cid)
      .then(res => {
        if (res.strategies_types) {
          dispatch(setStrategiesTypes(res.strategies_types))
        }
      })
      .catch(e => console.error('getCompanyKeys error ', e))
  }

  const getUserStrategies = () => {
    api
      .user(uid)
      .then(res => {
        localStorage.setItem('user', JSON.stringify(res))
        getStrategiesTypes(res.cid)
        _.map(res?.strategies, sid => {
          api.getUserStrategies(uid, sid)
            .then(res => {
              if (res.cid) {
                res["24h_vol"] = res["24h_vol"].reverse()
                if (!strategies.some(existingStrategy => existingStrategy.id === res.id)) {
                  dispatch(setStrategies([res]));
                }
              }
            })
            .catch(e => console.error('getUserStrategies error ', e));
        })
      })
      .catch(e => console.error('getUser error ', e))
  }

  const updateStrategies = () => {
    const currentStrategies = strategies; // Получаем текущие стратегии из Redux
    const updatePromises = currentStrategies.map(strategy => {
      return api.getUserStrategies(uid, strategy.strategy_id)
        .then(res => {
          return res;
        })
        .catch(e => {
          console.error(`Error updating strategy ${strategy.strategy_id}:`, e);
          return null;
        });
    });

    Promise.all(updatePromises).then(updatedStrategies => {
      const finalStrategies = currentStrategies.map(strategy => {
        const updatedStrategy = updatedStrategies.find(us => us && us.strategy_id === strategy.strategy_id);
        return updatedStrategy || strategy;
      });

      dispatch(updateStrategiesRedux(finalStrategies));
    });
  }


  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen)
  }

  const handleDeleteStrategy = async strategy => {
    setStrategyToDelete(strategy)
    toggleConfirmModal()
  }

  const confirmDeleteStrategy = async () => {
    toggleConfirmModal()
    api
      .deleteStrategy(strategyToDelete.strategy_id)
      .then(res => {
        if (res.success) {
          getUserStrategies()
        }
      })
      .catch(e => console.error('deleteStrategy error ', e))
  }

  // Генератор случайных цветов
  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return `#${randomColor}`
  }

  const colors = [
    "#7a5252", "#b6825c", "#6b41b6", "#5d5067", "#b44946",
    "#6f6a69", "#8b9871", "#4f98b1", "#9d804c", "#70665e",
    "#80936e", "#a95c9f", "#b79244", "#72624f", "#45a9a2",
    "#9b41bd", "#bdb192", "#b290ae", "#bc4c5a", "#85756e",
    "#716894", "#438aab", "#978f71", "#9d815e", "#7b5b81",
    "#415ba8", "#74545a", "#5f7e6f", "#51ae9f", "#714dab",
    "#564285", "#ad6f4a", "#7e7666", "#83a39d", "#9f8da6",
    "#93945a", "#beb2aa", "#8283b6", "#6ba853", "#579750",
    "#7da167", "#97817c", "#95836a", "#ac8266", "#574d8f",
    "#8b6763", "#4aa7b7", "#787841", "#9a61af", "#b45c94",
    "#7c457a", "#96438c", "#8e85a9", "#bb4266", "#60bb4e",
    "#ae6094", "#414fab", "#be8649", "#5e5d49", "#7d60ac",
    "#908848", "#a97fad", "#a68a6a", "#b84c47", "#514043",
    "#44a87e", "#63a4a7", "#5e4a74", "#59554e", "#534241",
    "#b37a49", "#6d8c9e", "#965f8a", "#46a840", "#505459",
    "#a3aa72", "#67a96b", "#4a68aa", "#76b2a6", "#5d598f",
    "#5e9074", "#ad8ba4", "#7d6595", "#617c58", "#47bc8a",
    "#495f6f", "#65bc8c", "#805478", "#4f486f", "#67815a",
    "#63b649", "#929f83", "#697e9b", "#b671b7", "#47ac67",
    "#82498f", "#b54ba4", "#538b4f", "#777166", "#53568c",
    "#8b7044", "#7cb07f", "#64685b", "#50b34f", "#72bd7c",
    "#6a78ae", "#abb955", "#4b6fa2", "#525497", "#aa75ae",
    "#678f7e", "#5088ab", "#5653b9", "#ada49f", "#a9a855",
    "#67b455", "#7c5170", "#a6435e", "#437f89", "#81654e",
    "#9b7a5d", "#5f697c", "#b591bb", "#7fb1b3", "#9ca146",
    "#b06085", "#bb6f6d", "#58af7c", "#5a9299", "#b47553",
    "#98a94f", "#5ebc9e", "#68be6c", "#be6078", "#4c4c90",
    "#b4af43", "#71a6a8", "#8c9e7b", "#91935b", "#a96982",
    "#9fab50", "#b793a5", "#6b826f", "#46a476", "#a694b2",
    "#87be6e", "#6d825c", "#767e99", "#50b9ae", "#7d5c98",
    "#6ca24d", "#755371", "#7e4767", "#678799", "#9e7e6d",
    "#908279", "#8b6c4c", "#908a40", "#659eac", "#855276",
    "#488791", "#95ad41", "#95a370", "#a05aa5", "#8f426a",
    "#597a8a", "#8881a7", "#58a589", "#7c9f5b", "#846e80",
    "#5c6170", "#a88b5c", "#9e7f54", "#54637b", "#a67b8b",
    "#5a645f", "#8f7d75", "#8b7b4c", "#9a8b7b", "#64626b",
    "#9c5f8a", "#757694", "#a74e75", "#5e604c", "#a08481",
    "#616b5e", "#655aa7", "#5c6271", "#a46e66", "#a89059"
  ]


  useEffect(() => {
    if (uid) {
      getUserStrategies()
    } else {
      logOut();
    }
  }, []); // Зависимости эффекта

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (uid) {
        updateStrategies(true);
      } else {
        logOut();
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [uid, strategies]); // Добавить strategies как зависимость

  useEffect(() => {
    // Создайте объект для инициализации состояния
    const initialStrategyStates = {}

    const weekData = [];
    filteredStrategies.forEach(strategy => {
      initialStrategyStates[strategy.strategy_id] = strategy.active;

      // Начнем с копирования существующих значений
      const paddedWeekVol = strategy.week_vol.slice();

      // Добавим нули до тех пор, пока длина массива не достигнет 30
      while (paddedWeekVol.length < 30) {
        paddedWeekVol.push(0);
      }

      weekData.push(paddedWeekVol);
    });

    setStrategyStates(initialStrategyStates);
    setWeek_vol_data(weekData);
  }, [strategies, lastUpdated])


  return (
    <>
      <div className='content'>
        <Row>
          <Col xs='12'>
            <div className='hideXS'>
              {_.size(week_vol_data) && (
                <WeekChart data={week_vol_data} backgroundColors={colors}/>
              )}
            </div>
            <div className='d-flex justify-content-between align-items-end mb-3 w-100 fdcXS aieXS'>
              <h2 className={'tacXS'}>{t('strategy.title')}</h2>
              {(isAdmin() || isUser()) && (
                <Button
                  className='btn-default'
                  color='info'
                  size='sm'
                  onClick={() => {
                    navigate('/admin/strategy/add')
                  }}>
                  {t('strategy.create')}
                </Button>
              )}
            </div>
            <div className='d-flex justify-content-between align-items-end mb-3 w-100 fdcXS btXS'>
              <div className='d-flex justify-content-start align-items-end mb-3 w-100 fdcXS'>
                {/* Фильтр по бирже */}
                <Select
                  isMulti
                  options={exchangeOptions}
                  value={exchangeFilter}
                  onChange={handleExchangeFilterChange}
                  placeholder={t('strategy.selectExchange')}
                  styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false)}
                  className='mr-3 w100pXS selectFilters'
                />
                {/* Фильтр по паре */}
                <Select
                  isMulti
                  options={pairOptions}
                  value={pairFilter}
                  onChange={handlePairFilterChange}
                  placeholder={t('strategy.selectPair')}
                  styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false)}
                  className='mr-3 w100pXS selectFilters'
                />
                {/* Фильтр по типу стратегии */}
                <Select
                  isMulti
                  options={strategyTypeOptions}
                  value={strategyTypeFilter}
                  onChange={handleStrategyTypeFilterChange}
                  placeholder={t('strategy.selectType')}
                  styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false)}
                  className='mr-3 w100pXS selectFilters'
                />
                <div className='form-group mb-0 w-25 w100pXS'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('strategy.searchPlaceholder')}
                    value={searchText}
                    onChange={handleSearchTextChange}
                  />
                </div>
              </div>
              <label className='d-flex justify-content-between align-items-center flex-shrink-0 ml-3 ml-3XS'>
                <Toggle
                  checked={showOnlyActive}
                  onChange={handleOnlyActiveChange}
                  className='mr-2'
                />
                <span className='text-white'>{t('strategy.showOnlyActive')}</span>
              </label>
            </div>
            <Table responsive>
              <thead>
              <tr>
                <th className='text-left' style={{width: '10%'}}>
                  {t('strategy.table.chart')}
                </th>
                <th className='text-center' style={{width: '16%'}}>
                  {t('strategy.table.name')}
                </th>
                <th className='text-center' style={{width: '16%'}}>
                  {t('strategy.table.type')}
                </th>
                <th className='text-center' style={{width: '16%'}}>
                  {t('strategy.table.exchange')}
                </th>
                <th className='text-center' style={{width: '16%'}}>
                  {t('strategy.table.pair')}
                </th>
                <th className='text-center' style={{width: '16%'}}>
                  {t('strategy.table.balance')}
                </th>
                <th className='text-right' style={{width: '10%'}}></th>
              </tr>
              </thead>
              <tbody>
              {_.map(_.sortBy(filteredStrategies, strategies => strategies.exchange), (strategy, index) => {
                return (
                  <tr key={strategy.strategy_id}>
                    <td className='text-center' style={{maxWidth: '185px'}}>
                      <Chart
                        values={strategy['24h_vol']}
                        backgroundColor={colors[index] || '#fff'}
                      />
                    </td>
                    <td className='text-center'>{strategy?.strategy_name}</td>
                    <td className='text-center'>{strategiesTypes.find(type => type.strategy_type_id === strategy.strategy_type)?.strategy_type_name}</td>
                    <td className='text-center'>{strategy?.exchange}</td>
                    <td className='text-center'>
                      {strategy?.pair?.base}-{strategy?.pair?.quote}
                    </td>
                    <td className='text-center'>
                      {strategy?.pair?.base}: {strategy?.balances?.base}
                      <br/> {strategy?.pair?.quote}: {strategy?.balances?.quote}
                    </td>
                    <td className='text-right d-flexXS'>
                      <div className='d-flex justify-content-center align-items-center '>
                        {strategy?.active ? (
                          ''
                        ) : (
                          <span className='badge badge-danger' style={{padding: '.5em'}}>
                              {strategy.status}
                            </span>
                        )}
                        <Toggle
                          className='ml-1'
                          checked={strategyStates[strategy.strategy_id] || false}
                          onChange={() => handleToggleChange(strategy.strategy_id)}
                          disabled={isWatchdog()}
                        />
                      </div>
                      <div className='d-flex d-flexXS justify-content-center align-items-center mt-2'>
                        <Button
                          className='btn-default mr-2'
                          color='info'
                          size='sm'
                          onClick={() => {
                            navigate(`deals/${strategy.strategy_id}`)
                          }}>
                          {t('strategy.table.orders')}
                        </Button>
                        {(isAdmin() || isUser()) && (
                          <>
                            <Button
                              className='btn-icon mr-1'
                              color='info'
                              size='sm'
                              onClick={() => {
                                navigate(`edit/${strategy.strategy_id}`)
                              }}>
                              <i className='fa fa-pencil'></i>
                            </Button>
                            <Button
                              className='btn-icon'
                              color='danger'
                              size='sm'
                              onClick={() => handleDeleteStrategy(strategy)}>
                              <i className='fa fa-trash'></i>
                            </Button>
                          </>
                        )}
                      </div>
                      <div className='d-flex d-flexXS justify-content-center align-items-center mt-2'>
                        <Button
                          className='btn-default'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            navigate(`/admin/deals`, {state: {
                                exchange: strategy.exchange,
                                pair: strategy.pair,
                                key_sets: strategy.key_sets
                              }})
                          }}>
                          Create deal
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        isOpen={confirmModalOpen}
        toggle={toggleConfirmModal}
        onConfirm={confirmDeleteStrategy}
        text={`Are you sure you want to delete ${strategyToDelete?.strategy_name}?`}
      />
    </>
  )
}

export default Strategy
