import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { useNavigate, Link } from 'react-router-dom'
import UnauthenticatedLayout from '../layouts/Unauthenticated/Unauthenticated'
import Api from '../api'

const Login = () => {
  const navigate = useNavigate()
  const api = new Api()

  const [formData, setFormData] = useState({
    login: '',
    password: '',
  })

  const [errors, setErrors] = useState({})
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    // Очищаем ошибку для текущего поля
    setErrors({ ...errors, [name]: '' })
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleSubmit = e => {
    e.preventDefault()

    // Валидация
    const validationErrors = {}
    if (!formData.login) {
      validationErrors.login = 'Login is required'
    }
    if (!formData.password) {
      validationErrors.password = 'Password is required'
    }

    // Если есть ошибки валидации, устанавливаем их и прекращаем отправку
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    const requestData = {
      login: formData.login,
      passwd: formData.password,
    }

    api
      .login(requestData)
      .then(res => {
        if (res) {
          localStorage.setItem('jwt', JSON.stringify(res))
          window.location.href = '/admin/strategy' // Перенаправление на страницу админки
        }
      })
      .catch(e => console.error('login error ', e))
  }

  // Проверяем авторизацию при загрузке приложения
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.uid) {
      // Если пользователь активен, перенаправляем на страницу админки
      navigate('/admin/strategy')
    }
  }, [navigate])

  return (
    <UnauthenticatedLayout>
      <Container className='d-flex vh-100'>
        <Row className='m-auto align-self-center w-100'>
          <Col className='m-auto' xl={6}>
            <Card>
              <CardBody>
                <h3 className='text-center mb-4'>Authorization</h3>
                <form onSubmit={handleSubmit} noValidate>
                  <FormGroup>
                    <Label for='login'>
                      Login<span className='text-danger'>*</span>
                    </Label>
                    <Input
                      type='text'
                      name='login'
                      id='login'
                      placeholder='Login'
                      value={formData.login}
                      onChange={handleChange}
                    />
                    {errors.login && <p className='text-danger'>{errors.login}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='password'>
                      Password<span className='text-danger'>*</span>
                    </Label>
                    <InputGroup>
                      <Input
                        type={passwordVisible ? 'text' : 'password'}
                        name='password'
                        id='password'
                        placeholder='Password'
                        autoComplete='off'
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <InputGroupAddon addonType='append'>
                        <Button
                          color='light'
                          onClick={togglePasswordVisibility}
                          className='border-0 bg-transparent btn-sm h-100 m-0'>
                          <i className={`fa-solid fa-eye${passwordVisible ? '-slash' : ''}`} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.password && <p className='text-danger'>{errors.password}</p>}
                  </FormGroup>
                  <div className='w-100 d-flex justify-content-center'>
                    <Button color='primary' type='submit' className='w-100'>
                      Login
                    </Button>
                  </div>
                </form>
                <div className='mt-3 text-center'>
                  {/* Добавляем ссылку на страницу регистрации */}
                  <Link to='/registration'>Don't have an account? Register here.</Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </UnauthenticatedLayout>
  )
}

export default Login
