import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import AdminLayout from 'layouts/Admin/Admin.js'
import Login from './views/Login'
import Registration from './views/Registration'
import AccessDenied from './views/AccessDenied'

const AppRoutes = () => {

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route
        path='/registration'
        element={<Registration />}
      />
      <Route path='/admin/*' element={<AdminLayout /> } />
      <Route
        path='/access-denied'
        element={<AccessDenied />}
      />
      <Route path='*' element={<AdminLayout />} />

      {/* Другие роуты */}
    </Routes>
  )
}

export default AppRoutes
