import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button} from 'reactstrap'
import Select from 'react-select'
import {reactSelectStyles} from '../variables/react-select-styles'
import Api from '../api'
import {useDispatch, useSelector} from 'react-redux'
import {setExchanges} from '../redux/actions/exchagesActions'
import {logOut} from '../hooks'
import {setKeysSets} from '../redux/actions/keysSetsActions'
import {isWatchdog} from '../roles'
import {setStrategiesTypes} from '../redux/actions/strategyActions'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import {setError, setSuccess} from '../redux/actions/alertActions'
import {reactSelectLightStyles} from "../variables/react-select-light-styles";

const StrategyForm = () => {
  const navigate = useNavigate()
  if (isWatchdog()) {
    navigate('/access-denied')
  }
  const api = new Api()
  const {id} = useParams()
  const dispatch = useDispatch()
  const exchangesData = useSelector(state => state.exchanges)
  const keysSetsData = useSelector(state => state.keysSets)
  const strategiesData = useSelector(state => state.strategy)
  const strategyId = Number(id)
  const [tradingPairs, setTradingPairs] = useState([])
  const [balance, setBalance] = useState([])
  const [balanceSlave, setBalanceSlave] = useState([])
  const [balanceLoading, setBalanceLoading] = useState(false)
  const [showTradingPair, setShowTradingPair] = useState(false)
  const [selectedExchange, setSelectedExchange] = useState(null)
  const [update_type, setUpdate_type] = useState({})
  const [formErrors, setFormErrors] = useState({}) // Состояние для ошибок валидации

  const [formData, setFormData] = useState({
    enable_all: true,
    name: '',
    type: '',
    exchange: null,
    tradingPair: null,
    keys: null,
    baseBalance: '',
    quoteBalance: '',
    baseBalanceSlave: '',
    quoteBalanceSlave: '',
  })
  const user = JSON.parse(localStorage.getItem('user'))
  const cid = user?.cid
  const uid = user?.uid
  const [onceApi, setOnceApi] = useState(false)
  const [onceApiSlave, setOnceApiSlave] = useState(false)

  useEffect(() => {
    if (strategyId && _.size(strategiesData.types) && _.size(keysSetsData)) {
      api
        .getStrategiesInfo(strategyId)
        .then(res => {
          if (res.strategy_name) {
            const findStrategyType = _.find(
              strategiesData.types,
              type => type.strategy_type_id === res.strategy_type,
            )
            setFormData({
              name: res.strategy_name,
              enable_all: false,
              active: res.active,
              type: {
                value: findStrategyType?.strategy_type_id,
                label: findStrategyType?.strategy_type_name,
                keys: findStrategyType?.key_numbers,
                config: findStrategyType?.config,
              },
              exchange: {value: res.exchange, label: res.exchange},
              tradingPair: {
                value: `${res.pair.base}-${res.pair.quote}`,
                label: `${res.pair.base}-${res.pair.quote}`,
              },
              keys:
                _.map(res.key_sets, key => {
                  const findKey = _.find(keysSetsData.keysSets, keyData => keyData.id === key);
                  if(findKey){
                    return {
                      value: findKey.id,
                      label: findKey.name
                    }
                  }
                }),
              baseBalance: res.balances?.base,
              quoteBalance: res.balances?.quote,
              baseBalanceSlave: res.balances_slave?.base,
              quoteBalanceSlave: res.balances_slave?.quote,
              ...res.config,
            })
            setSelectedExchange({value: res.exchange, label: res.exchange})
            setShowTradingPair(true)
          }
        })
        .catch(e => console.error('getStrategiesInfo error', e))
    }
  }, [strategyId, strategiesData, keysSetsData])

  useEffect(() => {
    if (cid) {
      api
        .getExchanges()
        .then(res => {
          if (res.exchanges) {
            dispatch(setExchanges(res.exchanges))
          }
        })
        .catch(e => console.error('getExchanges error ', e))
      api
        .getCompanyKeys(cid)
        .then(res => {
          if (res.KeysSets) {
            dispatch(setKeysSets(res.KeysSets))
          }
        })
        .catch(e => console.error('getCompanyKeys error ', e))
      api
        .getStrategiesTypes(cid)
        .then(res => {
          if (res.strategies_types) {
            dispatch(setStrategiesTypes(res.strategies_types))
          }
        })
        .catch(e => console.error('getCompanyKeys error ', e))
    } else {
      logOut()
    }
  }, [])

  useEffect(() => {
    if (_.first(formData.keys)?.value && !onceApi) {
      setOnceApi(true)
      setBalanceLoading(true)
      api.getBalance(_.first(formData.keys)?.value)
        .then(res => {
          if (res) {
            setBalance(res)
          }
        })
        .catch((e) => {
          console.error('getBalance error', e)
        })
        .finally(() => {
          setBalanceLoading(false)

        })
    }
    if (formData.keys?.[1]?.value && !onceApiSlave) {
      setOnceApiSlave(true)
      setBalanceLoading(true)
      api.getBalance(formData.keys?.[1]?.value)
        .then(res => {
          if (res) {
            setBalanceSlave(res)
          }
        })
        .catch((e) => {
          console.error('getBalanceSlave error', e)
        })
        .finally(() => {
          setBalanceLoading(false)

        })
    }
  }, [formData.keys]);

  const handleChange = (name, value) => {
    setFormData({...formData, [name]: value})
    setFormErrors({...formErrors, [name]: false})

    if (name === 'AutoRebalance') {
      setFormErrors({
        ...formErrors,
        StartRebalanceProc: false,
        StartSkipDealsProc: false,
        StopStrategyProc: false,
      })
    }
  }

  const handleStrategyTypeChange = selectedOption => {
    handleChange('type', selectedOption)
  }

  const handleExchangeChange = async (selectedOption) => {
    setSelectedExchange(selectedOption)
    setTradingPairs([])
    setOnceApi(false)
    setOnceApiSlave(false)
    setFormData({
      ...formData,
      tradingPair: null,
      keys: null,
      exchange: selectedOption,
    })

    try {
      const res = await api.getPairs(selectedOption.label)
      if (res.pairs) {
        setTradingPairs(res.pairs)
        setShowTradingPair(true)
      } else {
        setShowTradingPair(false)
      }
    } catch (e) {
      console.error('getPairs error', e)
    }
  }

  const handleKeysChange = (selectedOption, index) => {
    if (formData.keys === null) {
      setFormData({...formData, keys: [selectedOption]})
    } else {
      const updatedKeys = [...formData.keys]
      updatedKeys[index] = selectedOption
      setFormData({...formData, keys: updatedKeys})
    }
    setFormErrors({...formErrors, keys: false})
  }

  const handleTradingPairChange = selectedOption => {
    setFormData({
      ...formData,
      tradingPair: selectedOption,
      baseBalance: balance?.[selectedOption?.value?.split('-')?.[0]] || '',
      quoteBalance: balance?.[selectedOption?.value?.split('-')?.[1]] || '',
    })
  }

  // Функция для валидации формы
  const validateForm = () => {
    const errors = {}

    if (!formData.name) {
      errors.name = 'Strategy name is required'
    }

    if (!formData.type) {
      errors.type = 'Strategy type is required'
    }

    if (!formData.exchange) {
      errors.exchange = 'Exchange is required'
    }

    if (showTradingPair && !formData.tradingPair) {
      errors.tradingPair = 'Trading pair is required'
    }

    if (formData.type?.keys === 2 && (!formData.keys || formData.keys.length < 2)) {
      errors.keys = 'Both keys are required'
    } else if (!formData.keys || formData.keys.length < 1) {
      errors.keys = 'Key is required'
    }

    if (isNaN(parseFloat(formData.baseBalance))) {
      errors.baseBalance = 'Balance must be a number'
    }
    if (!formData.baseBalance) {
      errors.baseBalance = 'Balance is required'
    }
    if (formData.baseBalance > balance?.[formData?.tradingPair?.value?.split('-')?.[0]]) {
      errors.baseBalance = `Balance cannot be greater than ${
        balance?.[formData?.tradingPair?.value.split('-')?.[0]]
      }`
    }
    if (formData.baseBalance < 0) {
      errors.baseBalance = `Balance should be greater than 0`
    }

    if (isNaN(parseFloat(formData.quoteBalance))) {
      errors.quoteBalance = 'Balance must be a number'
    }
    if (!formData.quoteBalance) {
      errors.quoteBalance = 'Balance is required'
    }
    if (formData.quoteBalance > balance?.[formData?.tradingPair?.value?.split('-')?.[1]]) {
      errors.quoteBalance = `Balance cannot be greater than ${
        balance?.[formData?.tradingPair?.value?.split('-')?.[1]]
      }`
    }
    if (formData.quoteBalance < 0) {
      errors.quoteBalance = `Balance should be greater than 0`
    }

    if(formData.type.value === 1) {
      if (isNaN(parseFloat(formData.baseBalanceSlave))) {
        errors.baseBalanceSlave = 'Balance Slave must be a number'
      }
      if (!formData.baseBalanceSlave) {
        errors.baseBalanceSlave = 'Balance Slave is required'
      }
      if (formData.baseBalanceSlave > balanceSlave?.[formData?.tradingPair?.value?.split('-')?.[0]]) {
        errors.baseBalanceSlave = `Balance Slave cannot be greater than ${
          balanceSlave?.[formData?.tradingPair?.value.split('-')?.[0]]
        }`
      }
      if (formData.baseBalanceSlave < 0) {
        errors.baseBalanceSlave = `Balance Slave should be greater than 0`
      }

      if (isNaN(parseFloat(formData.quoteBalanceSlave))) {
        errors.quoteBalanceSlave = 'Balance Slave must be a number'
      }
      if (!formData.quoteBalanceSlave) {
        errors.quoteBalanceSlave = 'Balance Slave is required'
      }
      if (formData.quoteBalanceSlave > balanceSlave?.[formData?.tradingPair?.value?.split('-')?.[1]]) {
        errors.quoteBalanceSlave = `Balance Slave cannot be greater than ${
          balanceSlave?.[formData?.tradingPair?.value?.split('-')?.[1]]
        }`
      }
      if (formData.quoteBalanceSlave < 0) {
        errors.quoteBalanceSlave = `Balance Slave should be greater than 0`
      }
    }

    formData.type?.config?.forEach(field => {
      const fieldName = field.name
      const fieldValue = formData[fieldName]

      // Если AutoRebalance равно false и имя поля одно из указанных, пропустить валидацию
      if (
        formData['AutoRebalance'] === false &&
        (field.name === 'StartRebalanceProc' ||
          field.name === 'StartSkipDealsProc' ||
          field.name === 'StopStrategyProc')
      ) {
        return;
      }

      if (_.isNil(fieldValue)) {
        if (field.type === 'bool' && _.isUndefined(fieldValue)) {
          return
        }
        errors[fieldName] = `${field.label} is required`
      } else if (field.type === 'integer' && isNaN(parseInt(fieldValue))) {
        errors[fieldName] = `${field.label} must be an integer`
      } else if (field.type === 'float' && isNaN(parseFloat(fieldValue))) {
        errors[fieldName] = `${field.label} must be a number`
      } else if (field.type === 'string' && fieldValue.trim() === '') {
        errors[fieldName] = `${field.label} cannot be empty`
      }

      if (fieldValue < 0) {
        errors[fieldName] = `${field.label} should be greater than 0`
      }
      if (field.label.includes('%')) {
        if (fieldValue > 100) {
          errors[fieldName] = `${field.label} cannot be greater than 100`
        }
      }
    })

    return errors
  }

  const handleSubmit = e => {
    e.preventDefault()


    const errors = validateForm()

    // Если есть ошибки валидации, устанавливаем их и не отправляем данные
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
    } else {
      // Ошибок нет, можно отправить данные на бэкенд
      // Uid          int                json:"uid"
      // Cid          int                json:"cid"
      // StrategyId   int                json:"strategy_id"
      // StrategyName string             json:"strategy_name"
      // StrategyType StrategyType       json:"strategy_type"
      // Exchange     string             json:"exchange"
      // Pair         PairType           json:"pair"
      // Balance      BotStrategyBalance json:"balances"
      // BalanceSlave BotStrategyBalance json:"balances_slave"
      // Active       bool               json:"active"
      // Config       StrategyConfig     json:"config"
      // EnableAll    bool               json:"enable_all,omitempty" // if TRUE add strategy to all company users


      const requestData = {
        strategy_type: formData.type.value,
        data: {
          uid: uid,
          cid: cid,
          enable_all: formData.enable_all,
          strategy_type: formData.type.value,
          strategy_name: formData.name,
          exchange: formData.exchange.label,
          keys_sets_id_1: formData.keys[0]?.value,
          pair: {
            base: formData.tradingPair?.label?.split('-')?.[0],
            quote: formData.tradingPair?.label?.split('-')?.[1],
          },
          balances: {
            base: parseFloat(formData.baseBalance),
            quote: parseFloat(formData.quoteBalance),
          },
        }
      }

      if(formData.type.value === 1){
        requestData.data.balances_slave = {
          base: parseFloat(formData.baseBalanceSlave),
          quote: parseFloat(formData.quoteBalanceSlave),
        }
      }

      if (formData.keys[1]?.value) {
        requestData.data.keys_sets_id_2 = formData.keys[1]?.value
        requestData.data.balances_slave = {
          base: parseFloat(formData.baseBalanceSlave),
            quote: parseFloat(formData.quoteBalanceSlave),
        }
      }
      const config = {}
      formData.type?.config?.map(field => {
        const fieldName = field.name
        let fieldValue
        if (field.type === 'integer') {
          fieldValue = parseInt(formData[fieldName])
        } else if (field.type === 'float') {
          fieldValue = parseFloat(formData[fieldName])
        } else if (field.type === 'bool') {
          fieldValue = _.isBoolean(formData[fieldName]) ? formData[fieldName] : true
        } else if (field.type === 'string') {
          fieldValue = formData[fieldName]
        }
        config[fieldName] = fieldValue
      })

      requestData.data.config = config

      if (requestData.data.config.AutoRebalance === false) {
        requestData.data.config.StartRebalanceProc = 0
        requestData.data.config.StartSkipDealsProc = 0
        requestData.data.config.StopStrategyProc = 0
      }


      if (strategyId) {
        if (!_.size(update_type)) {
          dispatch(setError(`Nothing to update`))
          return
        }
        requestData.data.strategy_id = strategyId
        requestData.data.active = formData.active

        requestData.data.update_type =
          update_type.balance && !update_type.config
            ? 'BALANCE'
            : !update_type.balance && update_type.config
              ? 'CONFIG'
              : 'FULL'

        api
          .updateStrategy(requestData)
          .then(res => {
            if (res.success) {
              dispatch(setSuccess(`The strategy named ${formData.name} was successfully updated`))
              navigate('/admin/strategy')
            }
          })
          .catch(e => console.error('updateStrategy error ', e))
      } else {
        api
          .createStrategy(requestData)
          .then(res => {
            if (res.id) {
              dispatch(setSuccess(`The strategy named ${formData.name} was successfully created`))
              navigate('/admin/strategy')
            }
          })
          .catch(e => console.error('createStrategy error ', e))
      }
    }
  }

  const exchangeOptions =
    exchangesData?.exchanges?.map(exchange => ({
      value: exchange.eid,
      label: exchange.name,
    })) || []

  const strategiesTypeOptions =
    strategiesData?.types?.map(strategy => ({
      value: strategy.strategy_type_id,
      label: strategy.strategy_type_name,
      keys: strategy.key_numbers,
      config: strategy.config,
    })) || []

  const keyOptions =
    keysSetsData?.keysSets
      .filter(
        keySet =>
          !formData.keys || !formData.keys.some(selectedKey => selectedKey?.value === keySet.id),
      )
      .filter(keySet => !selectedExchange || keySet.exchange === selectedExchange.label)
      .map(keySet => ({
        value: keySet.id,
        label: keySet.name,
        exchange: keySet.exchange,
      })) || []


  return (
    <div className='content'>
      <Row>
        <Col className='' xl={6}>
          <Card>
            <CardBody>
              <h2>{strategyId ? 'Edit Strategy' : 'Add Strategy'}</h2>
              <form onSubmit={handleSubmit} noValidate>
                <FormGroup>
                  <Label for={'enable_all'}>
                    Enable all users
                  </Label>
                  <div className='d-flex align-items-center mt-2'>
                    <Toggle
                      id={'enable_all'}
                      name={'enable_all'}
                      checked={formData.enable_all}
                      defaultChecked={true}
                      onChange={e => {
                        handleChange('enable_all', e.target.checked)
                        setUpdate_type({
                          ...update_type,
                          config: true,
                        })
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for='name'>
                    Strategy Name<span className='text-danger'>*</span>
                  </Label>
                  <Input
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Enter strategy name'
                    value={formData.name}
                    onChange={e => handleChange('name', e.target.value)}
                    required
                    className='mb-0'
                    disabled={!!strategyId}
                  />
                  {formErrors.name && <p className='text-danger'>{formErrors.name}</p>}
                </FormGroup>

                <FormGroup>
                  <Label for='type'>
                    Strategy Type<span className='text-danger'>*</span>
                  </Label>
                  <Select
                    options={_.reverse(strategiesTypeOptions)}
                    value={formData.type}
                    onChange={handleStrategyTypeChange}
                    placeholder='Select Strategy Type'
                    styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(!!strategyId) : reactSelectLightStyles(!!strategyId) }
                    required
                    className='mb-0'
                    isDisabled={!!strategyId}
                  />
                  {formErrors.type && <p className='text-danger'>{formErrors.type}</p>}
                </FormGroup>

                <FormGroup>
                  <Label for='exchange'>
                    Exchange<span className='text-danger'>*</span>
                  </Label>
                  <Select
                    options={exchangeOptions}
                    value={formData.exchange}
                    onChange={handleExchangeChange}
                    placeholder='Select Exchange'
                    required={true}
                    styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(!!strategyId) : reactSelectLightStyles(!!strategyId) }
                    className='mb-0'
                    isDisabled={!!strategyId}
                  />
                  {formErrors.exchange && <p className='text-danger'>{formErrors.exchange}</p>}
                </FormGroup>

                {selectedExchange && (
                  <FormGroup>
                    <Label for='keys'>
                      {formData.type?.keys === 2 ? 'Keys' : 'Key'}
                      <span className='text-danger'>*</span>
                    </Label>
                    <Select
                      options={keyOptions}
                      value={formData.keys ? formData.keys[0] : null}
                      onChange={selectedOption => handleKeysChange(selectedOption, 0)}
                      placeholder='Select Key'
                      styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(!!strategyId) : reactSelectLightStyles(!!strategyId) }
                      required
                      className='mb-0'
                      isDisabled={!!strategyId}
                    />
                    {formData.type?.keys === 2 && (
                      <Select
                        options={keyOptions}
                        value={formData.keys ? formData.keys[1] : null}
                        onChange={selectedOption => handleKeysChange(selectedOption, 1)}
                        placeholder='Select Key'
                        styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(!!strategyId) : reactSelectLightStyles(!!strategyId) }
                        className='mt-3 mb-0'
                        required
                        // isDisabled={!!strategyId}
                      />
                    )}
                    {formErrors.keys && <p className='text-danger'>{formErrors.keys}</p>}
                  </FormGroup>
                )}

                {showTradingPair && (
                  <FormGroup>
                    <Label for='tradingPair'>
                      Trading Pair<span className='text-danger'>*</span>
                    </Label>
                    <Select
                      options={tradingPairs.map(pair => ({
                        value: pair,
                        label: pair,
                      }))}
                      value={formData.tradingPair}
                      onChange={handleTradingPairChange}
                      placeholder='Select Trading Pair'
                      styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(!!strategyId) : reactSelectLightStyles(!!strategyId) }
                      required
                      className='mb-0'
                      isDisabled={!!strategyId}
                    />
                    {formErrors.tradingPair && (
                      <p className='text-danger'>{formErrors.tradingPair}</p>
                    )}
                  </FormGroup>
                )}

                {formData.tradingPair && (
                  <>
                    <FormGroup>
                      <Label for='baseBalance'>
                        {formData?.tradingPair?.value?.split('-')?.[0]} Balance
                        (Available: {balance?.[formData?.tradingPair?.value?.split('-')?.[0]] || 0})
                      </Label>
                      <Input
                        type='number'
                        step='0.01'
                        name='baseBalance'
                        id='baseBalance'
                        placeholder={`Enter ${
                          formData?.tradingPair?.value?.split('-')?.[0]
                        } balance`}
                        value={formData.baseBalance}
                        onChange={e => {
                          handleChange('baseBalance', e.target.value)
                          setUpdate_type({
                            ...update_type,
                            balance: true,
                          })
                        }}
                        required
                        className='mb-0'
                        disabled={balanceLoading}
                      />
                      {formErrors.baseBalance && (
                        <p className='text-danger'>{formErrors.baseBalance}</p>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label for='quoteBalance'>
                        {formData?.tradingPair?.value?.split('-')?.[1]} Balance
                        (Available: {balance?.[formData?.tradingPair?.value?.split('-')?.[1]] || 0})
                      </Label>
                      <Input
                        type='number'
                        step='0.01'
                        name='quoteBalance'
                        id='quoteBalance'
                        placeholder={`Enter ${
                          formData?.tradingPair?.value?.split('-')?.[1]
                        } balance`}
                        value={formData.quoteBalance}
                        onChange={e => {
                          handleChange('quoteBalance', e.target.value)
                          setUpdate_type({
                            ...update_type,
                            balance: true,
                          })
                        }}
                        required
                        className='mb-0'
                        disabled={balanceLoading}
                      />
                      {formErrors.quoteBalance && (
                        <p className='text-danger'>{formErrors.quoteBalance}</p>
                      )}
                    </FormGroup>
                    {formData.type.value === 1 &&
                      <>
                      <FormGroup>
                        <Label for='baseBalanceSlave'>
                          {formData?.tradingPair?.value?.split('-')?.[0]} Balance Slave
                          (Available: {balanceSlave?.[formData?.tradingPair?.value?.split('-')?.[0]] || 0})
                        </Label>
                        <Input
                          type='number'
                          step='0.01'
                          name='baseBalanceSlave'
                          id='baseBalanceSlave'
                          placeholder={`Enter ${
                            formData?.tradingPair?.value?.split('-')?.[0]
                          } balance Slave`}
                          value={formData.baseBalanceSlave}
                          onChange={e => {
                            handleChange('baseBalanceSlave', e.target.value)
                            setUpdate_type({
                              ...update_type,
                              balance: true,
                            })
                          }}
                          required
                          className='mb-0'
                          disabled={balanceLoading}
                        />
                        {formErrors.baseBalanceSlave && (
                          <p className='text-danger'>{formErrors.baseBalanceSlave}</p>
                        )}
                      </FormGroup>

                        <FormGroup>
                          <Label for='quoteBalanceSlave'>
                            {formData?.tradingPair?.value?.split('-')?.[1]} Balance Slave
                            (Available: {balanceSlave?.[formData?.tradingPair?.value?.split('-')?.[1]] || 0})
                          </Label>
                          <Input
                            type='number'
                            step='0.01'
                            name='quoteBalanceSlave'
                            id='quoteBalanceSlave'
                            placeholder={`Enter ${
                              formData?.tradingPair?.value?.split('-')?.[1]
                            } balance Slave`}
                            value={formData.quoteBalanceSlave}
                            onChange={e => {
                              handleChange('quoteBalanceSlave', e.target.value)
                              setUpdate_type({
                                ...update_type,
                                balance: true,
                              })
                            }}
                            required
                            className='mb-0'
                            disabled={balanceLoading}
                          />
                          {formErrors.quoteBalanceSlave && (
                            <p className='text-danger'>{formErrors.quoteBalanceSlave}</p>
                          )}
                        </FormGroup>

                      </>
                    }
                  </>
                )}

                {formData.type?.config?.map(field => (
                  <div key={field.name}>
                    <FormGroup>
                      <Label for={field.name}>
                        {field.label}
                        <span className='text-danger'>*</span>
                      </Label>
                      {field.type === 'integer' ? (
                        <>
                          <Input
                            type='number'
                            id={field.name}
                            name={field.name}
                            value={_.isNil(formData[field.name]) ? '' : formData[field.name]}
                            onChange={e => {
                              handleChange(field.name, e.target.value)
                              setUpdate_type({
                                ...update_type,
                                config: true,
                              })
                            }}
                            placeholder={`Enter ${field.label.toLowerCase()}`}
                            required={field.required}
                            className='mb-0'
                            disabled={
                              formData['AutoRebalance'] === false &&
                              (field.name === 'StartRebalanceProc' ||
                                field.name === 'StartSkipDealsProc' ||
                                field.name === 'StopStrategyProc')
                            }
                          />
                        </>
                      ) : field.type === 'float' ? (
                        <Input
                          type='number'
                          step='0.01'
                          id={field.name}
                          name={field.name}
                          value={_.isNil(formData[field.name]) ? '' : formData[field.name]}
                          onChange={e => {
                            handleChange(field.name, e.target.value)
                            setUpdate_type({
                              ...update_type,
                              config: true,
                            })
                          }}
                          placeholder={`Enter ${field.label.toLowerCase()}`}
                          required={field.required}
                          className='mb-0'
                        />
                      ) : field.type === 'bool' ? (
                        <div className='d-flex align-items-center mt-2'>
                          <Toggle
                            id={field.name}
                            name={field.name}
                            checked={_.isBoolean(formData[field.name]) ? formData[field.name] : true}
                            onChange={e => {
                              handleChange(field.name, e.target.checked)
                              setUpdate_type({
                                ...update_type,
                                config: true,
                              })
                            }}
                          />
                        </div>
                      ) : (
                        <Input
                          type='text'
                          id={field.name}
                          name={field.name}
                          value={formData[field.name] || ''}
                          onChange={e => handleChange(field.name, e.target.value)}
                          placeholder={`Enter ${field.label.toLowerCase()}`}
                          required={field.required}
                          className='mb-0'
                        />
                      )}
                      {formErrors[field.name] && (
                        <p className='text-danger'>{formErrors[field.name]}</p>
                      )}
                    </FormGroup>
                  </div>
                ))}

                <div className='w-100 d-flex justify-content-end'>
                  <Button color='primary' type='submit'>
                    {strategyId ? 'Save' : 'Create'}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StrategyForm
