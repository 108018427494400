import React from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-moment'

const WeekChart = ({ data, backgroundColors }) => {
  const today = new Date()
  const formattedDates = []

  for (let i = 0; i < _.size(data[0]); i++) {
    const date = new Date(today)
    date.setDate(today.getDate() - i)
    const formattedDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    })
    formattedDates.push(formattedDate)
  }

  // Преобразование данных: USD слева, ETH справа
  const leftYData = data.map(values => values.map(value => value))
  const chartData = {
    labels: formattedDates.reverse(),
    datasets: [
      ...leftYData.map((values, index) => ({
        label: `Dataset ${index + 1}`,
        data: values.reverse(),
        fill: false,
        backgroundColor: backgroundColors[index],
        borderColor: backgroundColors[index],
        yAxisID: 'y', // Используем основную шкалу слева
      })),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest',
    },
    responsive: true,
    scales: {
      x: {},
      y: {
        title: {
          display: true,
          text: 'USD',
        },
      },
    },
  }

  return <Line data={chartData} options={options} />
}

export default WeekChart
