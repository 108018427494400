import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, CardFooter, FormGroup, Form, Input, Row, Col } from 'reactstrap'
import Api from '../api'
import { setError, setSuccess } from '../redux/actions/alertActions'
import { useDispatch } from 'react-redux'

function UserProfile() {
  const api = new Api()
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({})
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isDirty, setIsDirty] = useState(false) // Добавляем состояние для отслеживания изменений

  useEffect(() => {
    // Получаем данные пользователя из localstorage
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'))
    if (userFromLocalStorage) {
      setUserData(userFromLocalStorage)
    }
  }, [])

  // Функция для обработки изменений в полях ввода
  const handleInputChange = event => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
    setIsDirty(true) // Устанавливаем isDirty в true при изменении поля
  }

  // Функция для сохранения изменений
  const handleSave = () => {
    // Проверка, что пароль и подтверждение пароля совпадают
    if (password !== confirmPassword) {
      dispatch(setError('Passwords do not match'))
      return
    }

    // Отправьте данные на бэкэнд в ожидаемом формате, включая пароль и подтверждение пароля
    const newUserInfo = {
      uid: userData.uid,
      login: userData.login,
      cid: userData.cid,
      name: userData.name,
      email: userData.email,
      grp: userData.grp,
      passwd: password, // Передаем пароль
      active: userData.active,
      strategies: userData.strategies,
    }

    api.userUpdate(newUserInfo).then(res => {
      newUserInfo.passwd = ''
      dispatch(setSuccess('Success'))
      localStorage.setItem('user', JSON.stringify(newUserInfo))
    })

    // После успешной отправки данных можно обновить компонент, если это необходимо
    setIsDirty(false) // Сбрасываем isDirty после успешного сохранения
  }

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='6'>
            <Card>
              <CardBody>
                <h2>Profile</h2>
                <Form>
                  <Row>
                    {/*<Col className='pr-md-1' md='4'>*/}
                    {/*<FormGroup>*/}
                    {/*  <label>Company</label>*/}
                    {/*  <Input*/}
                    {/*    name='cid'*/}
                    {/*    value={userData.cid ? userData.cid.toString() : ''}*/}
                    {/*    placeholder='Company'*/}
                    {/*    type='text'*/}
                    {/*    onChange={handleInputChange}*/}
                    {/*    disabled={true}*/}
                    {/*  />*/}
                    {/*</FormGroup>*/}
                    {/*</Col>*/}
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Login</label>
                        <Input
                          name='login'
                          value={userData.login}
                          placeholder='Login'
                          type='text'
                          onChange={handleInputChange}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label>Group</label>
                        <Input
                          name='grp'
                          value={userData.grp}
                          placeholder='Group'
                          type='text'
                          onChange={handleInputChange}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          name='name'
                          value={userData.name}
                          placeholder='Name'
                          type='text'
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label htmlFor='exampleInputEmail1'>Email address</label>
                        <Input
                          name='email'
                          value={userData.email}
                          placeholder='Email'
                          type='email'
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          name='password'
                          value={password}
                          placeholder='Password'
                          type='password'
                          onChange={e => {
                            setPassword(e.target.value)
                            setIsDirty(true)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pl-md-1' md='6'>
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input
                          name='confirmPassword'
                          value={confirmPassword}
                          placeholder='Confirm Password'
                          type='password'
                          onChange={e => {
                            setConfirmPassword(e.target.value)
                            setIsDirty(true)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className='btn-fill'
                  color='primary'
                  onClick={handleSave}
                  disabled={!isDirty} // Делаем кнопку неактивной, если нет изменений
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UserProfile
