import Strategy from './views/Strategy'
import StrategyForm from './views/StrategyForm'
import Users from './views/Users'
import UserForm from './views/UserForm'
import ApiKeys from './views/ApiKeys'
import ApiKeysForm from './views/ApiKeysForm'
import StrategyDeals from './views/StrategyDeals'
import UserProfile from './views/UserProfile'
import { isAdmin, isUser, isWatchdog } from './roles'
import DealForm from "./views/DealForm";
import DealsForms from "./views/DealsForms";

var routes = [
  {
    path: '/strategy',
    name: 'Strategy',
    icon: 'fa-solid fa-chart-line',
    component: <Strategy />,
    layout: '/admin',
    showInSideBar: isAdmin() || isUser() || isWatchdog(),
  },
  {
    path: '/strategy/add',
    name: 'Strategy Add',
    icon: 'tim-icons icon-sound-wave',
    component: <StrategyForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/strategy/edit/:id',
    name: 'Strategy Edit',
    icon: 'tim-icons icon-sound-wave',
    component: <StrategyForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/strategy/deals/:strategyId',
    name: 'Strategy Add',
    icon: 'tim-icons icon-sound-wave',
    component: <StrategyDeals />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/apikeys',
    name: 'Api Keys',
    icon: 'fa fa-key',
    component: <ApiKeys />,
    layout: '/admin',
    showInSideBar: isAdmin(),
  },
  {
    path: '/apikeys/add',
    name: 'Api Keys Add',
    icon: 'fa fa-key',
    component: <ApiKeysForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/apikeys/edit/:id',
    name: 'Api Keys Edit',
    icon: 'fa fa-key',
    component: <ApiKeysForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/deals',
    name: 'Deals',
    icon: 'fa-solid fa-scale-unbalanced-flip',
    component: <DealsForms />,
    layout: '/admin',
    showInSideBar: isAdmin() || isUser() ,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'fa fa-user',
    component: <Users />,
    layout: '/admin',
    showInSideBar: isAdmin(),
  },
  {
    path: '/user/add',
    name: 'Users Add',
    icon: 'tim-icons fa fa-user',
    component: <UserForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/user/edit/:id',
    name: 'Users Edit',
    icon: 'tim-icons fa fa-user',
    component: <UserForm />,
    layout: '/admin',
    showInSideBar: false,
  },
  {
    path: '/user/profile',
    name: 'Users Profile',
    icon: 'tim-icons fa fa-user',
    component: <UserProfile />,
    layout: '/admin',
    showInSideBar: false,
  },
]
export default routes
