import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap'
import Select from 'react-select'
import { reactSelectStyles } from '../variables/react-select-styles'
import { useDispatch, useSelector } from 'react-redux'
import Api from '../api'
import { setSuccess } from '../redux/actions/alertActions'
import { isAdmin } from '../roles'
import {reactSelectLightStyles} from "../variables/react-select-light-styles";

const UserForm = () => {
  const navigate = useNavigate()
  if (!isAdmin()) {
    navigate('/access-denied')
  }
  const api = new Api()
  const dispatch = useDispatch()
  const { id } = useParams()
  const isEditing = id !== undefined // Определяем, редактируем ли мы существующего пользователя
  const cid = JSON.parse(localStorage.getItem('user'))?.cid

  const [formData, setFormData] = useState({
    cid: cid,
    login: '',
    email: '',
    name: '',
    grp: 'user',
    active: true,
    passwd: '',
    confirmPasswd: '',
    strategiesForm: [],
  })

  const [companyStrategies, setCompanyStrategies] = useState([])

  const [formErrors, setFormErrors] = useState({})

  const groupOptions = [
    { value: 'admin', label: 'admin' },
    { value: 'user', label: 'user' },
    { value: 'watchdog', label: 'watchdog' },
  ]

  const strategiesOptions = [
    { value: 'select-all', label: 'Select all' },
    ..._.map(_.clone(companyStrategies), strategy => {
      return {
        value: strategy.id,
        label: `Name: ${strategy.name}; Exchange: ${strategy.exchange}; Pair: ${strategy.pair.base}-${strategy.pair.quote}`,
      }
    }),
  ]

  // Получение пользователей из Redux
  const users = useSelector(state => state.users.users)

  // Поиск пользователя по id
  const currentUser = users.find(user => user.uid === Number(id))

  useEffect(() => {
    let strategies = []
    api
      .getCompanyStrategies(cid)
      .then(res => {
        if (res.cid && _.size(res.strategies)) {
          setCompanyStrategies(res.strategies)
          strategies = _.map(currentUser.strategies, userStrategy => {
            const findStrategy = _.find(
              res.strategies,
              companyStrategy => companyStrategy.id === userStrategy,
            )
            return {
              label: `Name: ${findStrategy.name}; Exchange: ${findStrategy.exchange}; Pair: ${findStrategy.pair.base}-${findStrategy.pair.quote}`,
              value: findStrategy.id,
            }
          })
        }
        if (isEditing && currentUser) {
          // Если пользователь найден, устанавливаем его данные в formData
          setFormData({
            ...formData,
            uid: currentUser.uid,
            login: currentUser.login,
            email: currentUser.email,
            name: currentUser.name,
            grp: currentUser.grp,
            active: currentUser.active,
            strategiesForm: strategies,
          })
        }
      })
      .catch(e => console.error('getCompanyStrategies error ', e))
  }, [id, isEditing, currentUser])

  const handleSubmit = e => {
    e.preventDefault()

    // Валидация формы
    const errors = {}
    if (!formData.login) {
      errors.login = 'Login is required'
    }
    if (!formData.email) {
      errors.email = 'Email is required'
    }
    if (!formData.name) {
      errors.name = 'Name is required'
    }
    if (!formData.grp) {
      errors.grp = 'Group is required'
    }
    if (!isEditing || (isEditing && formData.passwd)) {
      // Валидация пароля и подтверждения пароля только при создании пользователя или если пароль введен при редактировании
      if (!formData.passwd || formData.passwd.length < 8) {
        errors.passwd = 'Password must be at least 8 characters long'
      }
      if (formData.passwd !== formData.confirmPasswd) {
        errors.confirmPasswd = 'Passwords do not match'
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    formData.strategies = formData.strategiesForm.map(item => item.value)

    if (isEditing) {
      api
        .userUpdate(formData)
        .then(res => {
          if (res?.success) {
            dispatch(setSuccess(`user ${formData.name} has been successfully updated`))
            navigate('/admin/users')
          }
        })
        .catch(e => console.error('userUpdate error ', e))
    } else {
      api
        .registration(formData)
        .then(res => {
          if (res?.uid) {
            dispatch(setSuccess(`user ${formData.name} has been successfully created`))
            navigate('/admin/users')
          }
        })
        .catch(e => console.error('registration error ', e))
    }
  }

  return (
    <div className='content'>
      <Row>
        <Col className='' xl={6}>
          <Card>
            <CardBody>
              <h2>{isEditing ? 'Edit User' : 'Add User'}</h2>
              <form onSubmit={handleSubmit} noValidate>
                <FormGroup>
                  <Label for='login'>Login</Label>
                  <Input
                    type='text'
                    name='login'
                    id='login'
                    placeholder='Enter login'
                    value={formData.login}
                    onChange={e => setFormData({ ...formData, login: e.target.value })}
                    required
                    disabled={isEditing}
                  />
                  {formErrors.login && <div className='text-danger'>{formErrors.login}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for='email'>Email</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Enter email'
                    value={formData.email}
                    onChange={e => setFormData({ ...formData, email: e.target.value })}
                    required
                  />
                  {formErrors.email && <div className='text-danger'>{formErrors.email}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for='name'>Name</Label>
                  <Input
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Enter name'
                    value={formData.name}
                    onChange={e => setFormData({ ...formData, name: e.target.value })}
                  />
                  {formErrors.name && <div className='text-danger'>{formErrors.name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for='grp'>Group</Label>
                  <Select
                    options={groupOptions}
                    value={{ value: formData.grp, label: formData.grp }}
                    onChange={selectedOption =>
                      setFormData({ ...formData, grp: selectedOption ? selectedOption.value : '' })
                    }
                    placeholder='Select group'
                    styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
                  />
                  {formErrors.grp && <div className='text-danger'>{formErrors.grp}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for='strategies'>Strategies</Label>
                  <Select
                    options={strategiesOptions}
                    value={formData.strategiesForm}
                    onChange={selectedOption => {
                      if (selectedOption.some(option => option.value === 'select-all')) {
                        setFormData({
                          ...formData,
                          strategiesForm: strategiesOptions.filter(
                            option => option.value !== 'select-all',
                          ),
                        })
                      } else {
                        setFormData({
                          ...formData,
                          strategiesForm: selectedOption,
                        })
                      }
                    }}
                    placeholder='Select strategies'
                    styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(false) : reactSelectLightStyles(false) }
                    isMulti
                  />
                  {formErrors.strategiesForm && (
                    <div className='text-danger'>{formErrors.strategiesForm}</div>
                  )}
                </FormGroup>
                <>
                  <FormGroup>
                    <Label for='passwd'>Password</Label>
                    <Input
                      type='password'
                      name='passwd'
                      id='passwd'
                      placeholder='Enter password'
                      value={formData.passwd}
                      onChange={e => setFormData({ ...formData, passwd: e.target.value })}
                      required={!isEditing}
                    />
                    {formErrors.passwd && <div className='text-danger'>{formErrors.passwd}</div>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='confirmPasswd'>Confirm Password</Label>
                    <Input
                      type='password'
                      name='confirmPasswd'
                      id='confirmPasswd'
                      placeholder='Confirm password'
                      value={formData.confirmPasswd}
                      onChange={e => setFormData({ ...formData, confirmPasswd: e.target.value })}
                      required={!isEditing}
                    />
                    {formErrors.confirmPasswd && (
                      <div className='text-danger'>{formErrors.confirmPasswd}</div>
                    )}
                  </FormGroup>
                </>
                <div className='w-100 d-flex justify-content-between'>
                  <Button color='primary' type='submit'>
                    {isEditing ? 'Update User' : 'Save User'}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UserForm
