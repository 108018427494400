export const reactSelectLightStyles = disabled => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: disabled ? 'rgba(29, 37, 59, 0.1)' : 'transparent', // Светлый фон для отключенного состояния
    color: disabled ? 'rgba(34, 42, 66, 0.5)' : '#222a42', // Светлый цвет текста
    borderColor: state.isFocused ? '#222a42' : 'rgba(29, 37, 59, 0.5)', // Изменение цвета рамки
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#222a42' : 'rgba(29, 37, 59, 0.5)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(29, 37, 59, 0.1)' : disabled ? 'rgba(29, 37, 59, 0.1)' : 'transparent',
    color: state.isSelected ? '#222a42' : disabled ? 'rgba(34, 42, 66, 0.5)' : 'black',
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: 'rgba(29, 37, 59, 0.1)',
    color: '#222a42',
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#222a42',
  }),
  input: provided => ({
    ...provided,
    color: disabled ? 'rgba(34, 42, 66, 0.5)' : '#222a42',
    paddingLeft: '8px',
  }),
  singleValue: provided => ({
    ...provided,
    color: disabled ? 'rgba(34, 42, 66, 0.5)' : '#222a42',
    paddingLeft: '8px',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgb(108, 117, 124)', // Светлый цвет для плейсхолдера
    paddingLeft: '8px',
  }),
})
