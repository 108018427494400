import {useDispatch} from 'react-redux'
import {setError} from '../redux/actions/alertActions'
import config from '../config.json'
import {logOut} from "../hooks";


export default class Config {
  constructor() {
    this.dispatch = useDispatch()
  }

  request = async (url, method = 'POST', body) => {

    let fullUrl =  ''

    if (process.env.NODE_ENV === 'development'){
      fullUrl = config.serverUrl

      if(config.serverPort) {
        fullUrl = fullUrl + ':' + config.serverPort
      }
    } else {
      fullUrl = fullUrl + '/api'
    }

    fullUrl = fullUrl + '/' + url

    if (method === 'GET' && body !== '') {
      fullUrl = fullUrl + '?' + body.toString()
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const jwt = JSON.parse(localStorage.getItem('jwt'))
    if(jwt?.jwt){
      headers['DUJWT'] = jwt?.jwt
    }
    const res = await fetch(fullUrl, {
      method: method,
      headers: headers,
      body: method !== 'GET' ? JSON.stringify(body) : null,
    })

    try {
      if (res.ok) {
        if (res) {
          return await res.json()
        }
      } else {
        if(res.status === 401){
          logOut()
        }
        // Обработка ошибок с сервера
        this.dispatch(
          setError(`Server Error: ${res.status}${res.statusText ? ', ' + res.statusText : ''}`),
        )
        return null
      }
    } catch (error) {
      // Обработка ошибок при парсинге JSON
      this.dispatch(setError('JSON Parse Error'))
      return null
    }
  }
}
