import React, { useState, useEffect } from 'react';
import Toggle from 'react-toggle';

const ThemeToggle = () => {
  const [isDark, setIsDark] = useState(false); // Default to light theme

  useEffect(() => {
    // Загрузка предпочтений темы из localStorage при инициализации
    const savedTheme = localStorage.getItem("darkMode");
    if (savedTheme !== null) {
      const darkMode = JSON.parse(savedTheme);
      setIsDark(darkMode);

      if (darkMode) {
        document.body.classList.remove("white-content");
      } else {
        document.body.classList.add("white-content");
      }
    } else if (document.body.classList.contains("white-content")) {
      setIsDark(false);
    } else {
      setIsDark(true);
    }
  }, []);

  const handleThemeChange = () => {
    setIsDark(!isDark);

    // Сохранение предпочтений темы в localStorage
    localStorage.setItem("darkMode", JSON.stringify(!isDark));
    window.location.reload()
    //
    // if (document.body.classList.contains("white-content")) {
    //   document.body.classList.remove("white-content");
    // } else {
    //   document.body.classList.add("white-content");
    // }
    //
    // window.dispatchEvent(new Event('themeChanged'));

  };

  return (
    <div className="theme-toggle" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <span>Light Mode</span>
      <Toggle
        checked={isDark}
        onChange={handleThemeChange}
        icons={false}
        aria-label="Toggle dark mode on/off"
      />
      <span>Dark Mode</span>
    </div>
  );
}

export default ThemeToggle;
