/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="mailto:abc@example.com">
              Contact us
            </NavLink>
          </NavItem>
          {/*<NavItem>*/}
          {/*  <NavLink href="https://www.creative-tim.com/presentation?ref=bdr-user-archive-footer">*/}
          {/*    About Us*/}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
          {/*<NavItem>*/}
          {/*  <NavLink href="https://www.creative-tim.com/blog?ref=bdr-user-archive-footer">*/}
          {/*    Blog*/}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a
            href="http://du.xforsa.ru/"
            target="_blank"
          >
            Digital Ursa
          </a>{" "}
          for a better web.
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
