// LanguageSwitcher.js
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' },
    // Добавьте другие языки по аналогии
  ]

  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle caret nav>
        <i className='fas fa-globe mr-2' /> {i18n.language.toUpperCase()}
      </DropdownToggle>
      <DropdownMenu right>
        {languages.map(lang => (
          <DropdownItem key={lang.code} onClick={() => changeLanguage(lang.code)}>
            {lang.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default LanguageSwitcher
