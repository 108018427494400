import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import './style.css'

const ConfirmModal = ({ isOpen, toggle, onConfirm, text }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-danger'>
      <ModalBody>{text}</ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={onConfirm} className='btn-simple'>
          Delete
        </Button>
        <Button color='secondary' onClick={toggle} className='btn-simple'>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal
