import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import 'assets/scss/black-dashboard-react.scss'
import 'assets/demo/demo.css'
import 'assets/css/nucleo-icons.css'

import ThemeContextWrapper from './components/ThemeWrapper/ThemeWrapper'
import BackgroundColorWrapper from './components/BackgroundColorWrapper/BackgroundColorWrapper'
import { Provider } from 'react-redux'
import store from './redux/store'
import i18n from './locales/i18n'
import './assets/css/custom-style.css'
import './assets/css/media.css'
import AppRoutes from './appRoutes'
import AlertComponent from './components/Alert'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <Provider store={store}>
        <BrowserRouter>
          <AlertComponent />
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
)
