import React, {useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import UnauthenticatedLayout from '../layouts/Unauthenticated/Unauthenticated';
import Api from '../api';

const Registration = () => {
  const navigate = useNavigate();
  const api = new Api();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    country: '',
    username: '',
    fullName: '',
    userEmail: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [useCompanyEmail, setUseCompanyEmail] = useState(true);


  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) validationErrors.name = 'Company name is required';
    if (!formData.email) validationErrors.email = 'Email is required';
    if (!useCompanyEmail && !formData.userEmail) validationErrors.userEmail = 'Personal email is required';
    else if (!isValidEmail(formData.email)) validationErrors.email = 'Invalid email format';
    // if (!formData.address) validationErrors.address = 'Address is required';
    // if (!formData.country) validationErrors.country = 'Country is required';
    if (!formData.username) validationErrors.username = 'Login is required';
    if (!formData.fullName) validationErrors.fullName = 'Full Name is required';
    if (formData.password.length < 8 || formData.password.toLowerCase() === formData.password) {
      validationErrors.password = 'Password must be at least 8 characters long and include at least one uppercase letter.';
    }
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const requestData = {
      name: formData.name,
      email: formData.email,
      address: formData.address,
      country: formData.country,
      user: {
        login: formData.username,
        name: formData.fullName,
        passwd: formData.password,
        email: useCompanyEmail ? formData.email : formData.userEmail,
      },
    };


    api
      .companyRegistration(requestData)
      .then(res => {
        if (res.active) {
          localStorage.setItem('user', JSON.stringify(res));
          window.location.href = '/admin/strategy';
        } else {
          navigate('/login');
        }
      })
      .catch(e => console.error('registration error ', e));
  };

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.uid) {
      // Если пользователь активен, перенаправляем на страницу админки
      navigate('/admin/strategy')
    }
  }, [navigate])

  return (
    <UnauthenticatedLayout>
      <Container className='d-flex vh-100'>
        <Row className='m-auto align-self-center w-100'>
          <Col className='m-auto' md='6'>
            <Card>
              <CardBody>
                <h3 className='text-center mb-4'>Registration</h3>
                <form onSubmit={handleSubmit} noValidate>
                  <h4 className='text-center mb-4'>Company information<span className='text-danger'>*</span></h4>
                  <FormGroup>
                    <Label for='name'>Company name<span className='text-danger'>*</span></Label>
                    <Input type='text' name='name' id='name' placeholder='Enter company name' value={formData.name} onChange={handleChange} />
                    {errors.name && <p className='text-danger'>{errors.name}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='email'>E-mail<span className='text-danger'>*</span></Label>
                    <Input type='email' name='email' id='email' placeholder='Enter e-mail' value={formData.email} onChange={handleChange} />
                    {errors.email && <p className='text-danger'>{errors.email}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='address'>Address</Label>
                    <Input type='text' name='address' id='address' placeholder='Enter address' value={formData.address} onChange={handleChange} />
                    {errors.address && <p className='text-danger'>{errors.address}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='country'>Country</Label>
                    <Input type='text' name='country' id='country' placeholder='Enter country' value={formData.country} onChange={handleChange} />
                    {errors.country && <p className='text-danger'>{errors.country}</p>}
                  </FormGroup>
                  <h4 className='text-center mb-4'>User information (Admin rights)<span className='text-danger'>*</span></h4>
                  <FormGroup>
                    <Label for='fullName'>Full Name<span className='text-danger'>*</span></Label>
                    <Input type='text' name='fullName' id='fullName' placeholder='Enter full name' value={formData.fullName} onChange={handleChange} />
                    {errors.fullName && <p className='text-danger'>{errors.fullName}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='userEmail'>User Email </Label>
                    <Input type='email' name='userEmail' id='userEmail' placeholder='Enter user email' value={formData.userEmail} onChange={handleChange} disabled={useCompanyEmail} />
                    {errors.userEmail && <p className='text-danger'>{errors.userEmail}</p>}
                  </FormGroup>
                  <FormGroup className="custom-checkbox">
                    <Label check>
                      <Input type="checkbox" aria-label="Use company email" tabIndex='-1' checked={useCompanyEmail} onChange={e => setUseCompanyEmail(e.target.checked)} />
                      Use company email
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for='username'>Login<span className='text-danger'>*</span></Label>
                    <Input type='text' name='username' id='username' placeholder='Enter login' value={formData.username} onChange={handleChange} />
                    {errors.username && <p className='text-danger'>{errors.username}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='password'>Password<span className='text-danger'>*</span></Label>
                    <InputGroup>
                      <Input
                        type={passwordVisible ? 'text' : 'password'}
                        name='password'
                        id='password'
                        placeholder='Enter password'
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <InputGroupAddon addonType='append'>
                        <Button
                          color='light'
                          onClick={togglePasswordVisibility}
                          tabIndex='-1'
                          className='border-0 bg-transparent btn-sm h-100 m-0'>
                          <i className={`fa-solid fa-eye${passwordVisible ? '-slash' : ''}`} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.password && <p className='text-danger'>{errors.password}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for='confirmPassword'>Confirm Password<span className='text-danger'>*</span></Label>
                    <InputGroup>
                      <Input
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        name='confirmPassword'
                        id='confirmPassword'
                        placeholder='Confirm password'
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <InputGroupAddon addonType='append'>
                        <Button
                          color='light'
                          onClick={toggleConfirmPasswordVisibility}
                          tabIndex='-1'
                          className='border-0 bg-transparent btn-sm h-100 m-0'>
                          <i className={`fa-solid fa-eye${confirmPasswordVisible ? '-slash' : ''}`} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.confirmPassword && <p className='text-danger'>{errors.confirmPassword}</p>}
                  </FormGroup>
                  <Button type='submit' color='primary' className='w-100'>Register</Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </UnauthenticatedLayout>
  );
};

export default Registration;
