const initialState = {
  keysSets: [],
  // Другие данные о стратегиях
}

const KeysSetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_KEYSSETS':
      return {
        ...state,
        keysSets: action.payload,
      }
    // Другие обработчики действий для стратегий
    default:
      return state
  }
}

export default KeysSetsReducer
