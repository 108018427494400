import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Api from '../api'
import { useDispatch, useSelector } from 'react-redux'
import { setUsers } from '../redux/actions/usersActions'
import { isAdmin, isWatchdog } from '../roles'
import ConfirmModal from '../components/ConfirmModal'
import { setKeysSets } from '../redux/actions/keysSetsActions'

const Users = () => {
  const navigate = useNavigate()
  if (!isAdmin()) {
    navigate('/access-denied')
  }
  const api = new Api()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [showOnlyActive, setShowOnlyActive] = useState(false)
  const users = useSelector(state => state.users)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const cid = JSON.parse(localStorage.getItem('user'))?.cid

  useEffect(() => {
    if (cid) {
      api
        .getCompanyUsers(cid)
        .then(res => {
          if (res.users_info) {
            dispatch(setUsers(res.users_info))
          }
        })
        .catch(e => console.error('getCompanyUsers error ', e))
    }
  }, [])

  const handleToggleChange = uid => {
    setUsers(prevState => ({
      ...prevState,
      users_info: prevState.users_info.map(user =>
        user.uid === uid ? { ...user, active: !user.active } : user,
      ),
    }))
  }

  const handleOnlyActiveChange = () => {
    setShowOnlyActive(prevState => !prevState)
  }

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen)
  }

  const handleDeleteUser = async user => {
    setUserToDelete(user)
    toggleConfirmModal()
  }

  const confirmDeleteUser = async () => {
    toggleConfirmModal()
    api
      .userDelete(userToDelete.uid)
      .then(res => {
        console.log(res)
        if (res.success) {
          api
            .getCompanyUsers(cid)
            .then(res => {
              if (res.users_info) {
                dispatch(setUsers(res.users_info))
              }
            })
            .catch(e => console.error('getCompanyUsers error ', e))
        }
      })
      .catch(e => console.error(e))
  }

  const filteredUsers = _.filter(users.users, user => {
    const matchesSearch =
      user?.email?.includes(searchText) ||
      user?.login?.includes(searchText) ||
      user?.name?.includes(searchText)

    if (showOnlyActive) {
      return matchesSearch && user.active
    } else {
      return matchesSearch
    }
  })

  return (
    <>
      <div className='content'>
        <Row>
          <Col xs={12}>
            <div className='d-flex justify-content-between align-items-end mb-3 fdcXS aieXS'>
              <h2 className={'tacXS'}>{t('users.title')}</h2>
              <Button
                className='btn-default'
                color='info'
                size='sm'
                onClick={() => {
                  navigate('/admin/user/add')
                }}>
                {t('users.createUser')}
              </Button>
            </div>
            <div className='d-flex justify-content-between align-items-end mb-3 fdcXS btXS'>
              <div className='form-group w-25 w100pXS'>
                <input
                  type='text'
                  className='form-control'
                  placeholder={t('users.searchPlaceholder')}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <label className='d-flex justify-content-between align-items-center ml-3 ml-3XS'>
                <Toggle
                  checked={showOnlyActive}
                  onChange={handleOnlyActiveChange}
                  className='mr-2'
                />
                <span className='text-white'>{t('users.showOnlyActive')}</span>
              </label>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th className='text-left' style={{ width: '22%' }}>
                    {t('users.table.login')}
                  </th>
                  <th className='text-center' style={{ width: '22%' }}>
                    {t('users.table.email')}
                  </th>
                  <th className='text-center' style={{ width: '22%' }}>
                    {t('users.table.name')}
                  </th>
                  <th className='text-center' style={{ width: '22%' }}>
                    {t('users.table.group')}
                  </th>
                  <th className='text-right' style={{ width: '12%' }}></th>
                </tr>
              </thead>
              <tbody>
                {_.sortBy(filteredUsers, 'uid').map(user => {
                  const matchesSearch =
                    !searchText ||
                    user.email.includes(searchText) ||
                    user.login.includes(searchText) ||
                    user.name.includes(searchText)

                  if (matchesSearch) {
                    return (
                      <tr key={user.uid}>
                        <td className='text-left'>{user.login}</td>
                        <td className='text-center'>{user.email}</td>
                        <td className='text-center'>{user.name}</td>
                        <td className='text-center'>{user.grp}</td>
                        <td className='text-center d-flexXS'>
                          <div className='d-flex justify-content-end align-items-center d-flexXS'>
                            <Toggle
                              checked={user.active}
                              onChange={() => handleToggleChange(user.uid)}
                              className='mr-1'
                            />
                            <>
                              <Button
                                className='btn-icon mr-1'
                                color='info'
                                size='sm'
                                onClick={() => {
                                  navigate(`/admin/user/edit/${user.uid}`)
                                }}>
                                <i className='fa fa-pencil'></i>
                              </Button>
                              <Button
                                className='btn-icon'
                                color='danger'
                                size='sm'
                                onClick={() => handleDeleteUser(user)}>
                                <i className='fa fa-trash'></i>
                              </Button>
                            </>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        isOpen={confirmModalOpen}
        toggle={toggleConfirmModal}
        onConfirm={confirmDeleteUser}
        text={`Are you sure you want to delete ${userToDelete?.name}?`}
      />
    </>
  )
}

export default Users
