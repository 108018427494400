import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap'
import Select from 'react-select'
import { reactSelectStyles } from '../variables/react-select-styles'
import Api from '../api'
import { useDispatch, useSelector } from 'react-redux'
import { setExchanges } from '../redux/actions/exchagesActions'
import { logOut } from '../hooks'
import { setKeysSets } from '../redux/actions/keysSetsActions'
import { setSuccess } from '../redux/actions/alertActions'
import { isAdmin } from '../roles'
import {reactSelectLightStyles} from "../variables/react-select-light-styles";

const ApiKeysForm = () => {
  const navigate = useNavigate()
  if (!isAdmin()) {
    navigate('/access-denied')
  }
  const api = new Api()
  const { id } = useParams()
  const dispatch = useDispatch()
  const exchangesData = useSelector(state => state.exchanges)
  const keysSetsData = useSelector(state => state.keysSets)
  const cid = JSON.parse(localStorage.getItem('user'))?.cid
  const isUpdate = !!id

  const [formData, setFormData] = useState({
    name: '',
    exchange: null,
    keys: [],
  })

  useEffect(() => {
    if (cid) {
      api
        .getExchanges()
        .then(res => {
          if (res.exchanges) {
            dispatch(setExchanges(res.exchanges))
          }
        })
        .catch(e => console.error('getExchanges error ', e))
    } else {
      logOut()
    }
  }, [])

  useEffect(() => {
    if (isUpdate) {
      const editedKey = keysSetsData.keysSets.find(key => key.id === parseInt(id, 10))
      if (editedKey) {
        const selectedExchange = exchangesData.exchanges.find(
          exchange => exchange.name === editedKey.exchange,
        )

        setFormData({
          name: editedKey.name,
          exchange: {
            value: selectedExchange?.eid,
            label: selectedExchange?.name,
          },
          keys: [editedKey.key1, editedKey.key2, editedKey.key3].filter(Boolean),
        })
      }
    } else {
      if (cid) {
        if (!exchangesData.exchanges.length) {
          api
            .getExchanges()
            .then(res => {
              if (res.exchanges) {
                dispatch(setExchanges(res.exchanges))
              }
            })
            .catch(e => console.error('getExchanges error ', e))
        }
      } else {
        logOut()
      }
    }
  }, [id, exchangesData, isUpdate])

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const handleExchangeChange = selectedOption => {
    handleChange('exchange', selectedOption)
  }

  const handleKeyChange = (value, index) => {
    const updatedKeys = [...formData.keys]
    updatedKeys[index] = value
    setFormData({ ...formData, keys: updatedKeys })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const requestBody = {
      name: formData.name,
      exchange: formData.exchange.label,
      key1: formData.keys[0],
      key2: formData.keys[1],
    }

    if (formData.keys[2]) {
      requestBody.key3 = formData.keys[2]
    }

    if (isUpdate) {
      requestBody.id = parseInt(id)
      requestBody.active = true
      api
        .updateApiKey(requestBody)
        .then(res => {
          if (res.success) {
            dispatch(setSuccess(`The key named ${formData.name} was successfully updated`))
            navigate('/admin/apikeys')
          }
        })
        .catch(e => console.error('updateApiKey error ', e))
    } else {
      requestBody.cid = cid
      api
        .createApiKey(requestBody)
        .then(res => {
          if (res.id) {
            dispatch(setSuccess(`The key named ${formData.name} was successfully created`))
            navigate('/admin/apikeys')
          }
        })
        .catch(e => console.error('createApiKey error ', e))
    }
  }

  const exchangeOptions =
    exchangesData?.exchanges?.map(exchange => ({
      value: exchange.eid,
      label: exchange.name,
    })) || []

  const selectedExchange = exchangesData.exchanges.find(
    exchange => exchange.eid === formData.exchange?.value,
  )

  const keyInputs =
    selectedExchange &&
    Array.from({ length: selectedExchange.keys_num }).map((_, index) => (
      <FormGroup key={index}>
        <Label for={`key${index + 1}`}>{`Key ${index + 1}`}</Label>
        <Input
          type='text'
          name={`key${index + 1}`}
          id={`key${index + 1}`}
          placeholder={`Enter key ${index + 1}`}
          value={formData.keys[index] || ''}
          onChange={e => handleKeyChange(e.target.value, index)}
          required
        />
      </FormGroup>
    ))

  return (
    <div className='content'>
      <Row>
        <Col className='' xl={6}>
          <Card>
            <CardBody>
              <h2>{isUpdate ? 'Edit API Key' : 'Add API Key'}</h2>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for='name'>Name</Label>
                  <Input
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Enter name'
                    value={formData.name}
                    onChange={e => handleChange('name', e.target.value)}
                    required
                    disabled={isUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='exchange'>Exchange</Label>
                  <Select
                    options={exchangeOptions}
                    value={formData.exchange}
                    onChange={handleExchangeChange}
                    placeholder='Select Exchange'
                    styles={localStorage.getItem('darkMode') === 'true' ? reactSelectStyles(isUpdate) : reactSelectLightStyles(isUpdate) }

                    required
                    isDisabled={isUpdate}
                  />
                </FormGroup>
                {keyInputs}
                <div className='w-100 d-flex justify-content-between'>
                  <Button color='primary' type='submit'>
                    {isUpdate ? 'Update API Key' : 'Add API Key'}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ApiKeysForm
