const initialState = {
  error: null,
  success: null,
}

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.error }
    case 'CLEAR_ERROR':
      return { ...state, error: null }
    case 'SET_SUCCESS':
      return { ...state, success: action.success }
    case 'CLEAR_SUCCESS':
      return { ...state, success: null }
    default:
      return state
  }
}

export default alertReducer
