import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

const AccessDenied = () => {
  return (
    <div className='content'>
      <Container className='d-flex align-items-center justify-content-center vh-100'>
        <Row>
          <Col md='8'>
            <Card className='card-plain'>
              <CardBody>
                <div className='error-page text-center'>
                  <h1 className='error-title'>Access Denied</h1>
                  <h4 className='error-subtitle'>
                    Sorry, you don't have permission to access this page.
                  </h4>
                  <p className='error-text'>
                    If you believe this is an error, please contact the administrator.
                  </p>
                  <a href='/' className='btn btn-primary'>
                    Go to Home
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AccessDenied
