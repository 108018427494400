import Config from './config'

export default class Api extends Config {
  ping = async () => {
    return await this.request('ping', 'GET', '')
  }

  login = async data => {
    return await this.request('login', 'POST', data)
  }
  companyRegistration = async data => {
    return await this.request('company/new', 'POST', data)
  }
  registration = async data => {
    return await this.request('users/new', 'POST', data)
  }
  user = async uid => {
    const params = new URLSearchParams({
      uid: uid,
    })
    return await this.request('users', 'GET', params)
  }

  userUpdate = async userData => {
    return await this.request('users/update', 'POST', userData)
  }
  userDelete = async uid => {
    const params = new URLSearchParams({
      uid: uid,
    })
    return await this.request('users/delete', 'GET', params)
  }

  getExchanges = async () => {
    return await this.request('exchanges', 'GET', '')
  }

  getCompanyStrategies = async cid => {
    const params = new URLSearchParams({
      cid: cid,
    })
    return await this.request('company/strategies', 'GET', params)
  }
  getUserStrategies = async (uid, sid) => {
    const params = new URLSearchParams({
      uid: uid,
      sid: sid,
    })
    return await this.request('users/strategy', 'GET', params)
  }
  getStrategyInfo = async (id, num) => {
    const params = new URLSearchParams({
      id: id,
      num: num,
    })
    return await this.request('strategies/info', 'GET', params)
  }
  getStrategiesTypes = async cid => {
    const params = new URLSearchParams({
      cid: cid,
    })
    return await this.request('strategies/types', 'GET', params)
  }
  getStrategiesInfo = async id => {
    const params = new URLSearchParams({
      id: id,
    })
    return await this.request('strategies/config_info', 'GET', params)
  }

  getBalance = async id => {
    const params = new URLSearchParams({
      id: id,
    })
    return await this.request('bot/balance', 'GET', params)
  }

  createStrategy = async strategyData => {
    return await this.request('strategies/new', 'POST', strategyData)
  }
  updateStrategy = async strategyData => {
    return await this.request('strategies/update', 'POST', strategyData)
  }
  deleteStrategy = async id => {
    const params = new URLSearchParams({
      id: id,
    })
    return await this.request('strategies/delete', 'GET', params)
  }
  toggleStrategy = async (id, toggleType) => {
    const params = new URLSearchParams({
      id: id,
    })
    if (toggleType) {
      return await this.request('strategies/start', 'GET', params)
    } else {
      return await this.request('strategies/stop', 'GET', params)
    }
  }
  getPairs = async exch => {
    const params = new URLSearchParams({
      exch: exch,
    })
    return await this.request('pairs', 'GET', params)
  }

  getCompanyUsers = async cid => {
    const params = new URLSearchParams({
      cid: cid,
    })
    return await this.request('company/users', 'GET', params)
  }

  getCompanyKeys = async cid => {
    const params = new URLSearchParams({
      cid: cid,
    })
    return await this.request('company/keys_sets', 'GET', params)
  }

  createApiKey = async data => {
    return await this.request('keys_sets/new', 'POST', data)
  }
  updateApiKey = async data => {
    return await this.request('keys_sets/update', 'POST', data)
  }

  disableApiKey = async key => {
    const params = new URLSearchParams({
      id: key.id,
      todo: key.active ? 'stop' : 'start',
    })
    return await this.request('bots', 'GET', params)
  }
  deleteApiKey = async key => {
    const params = new URLSearchParams({
      id: key.id,
    })
    return await this.request('bots/delete', 'GET', params)
  }

  createDeal = async params => {
    return await this.request('bot/deal', 'POST', params)
  }

  updateDealStatus = async id => {
    const params = new URLSearchParams({
      id: id,
    })

    return await this.request('bot/deal/status', 'GET', params)
  }
  cancelDeal = async id => {
    const params = new URLSearchParams({
      id: id,
    })

    return await this.request('bot/deal/cancel', 'GET', params)
  }


}
