import React, {useEffect, useState} from 'react'
import {Table, Button, Row, Col} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import {useParams, Link, useHistory, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import Api from '../api'

const StrategyDeals = () => {
  const api = new Api()
  const {t} = useTranslation()
  const {strategyId} = useParams()
  const navigate = useNavigate()

  const [deals, setDeals] = useState([])
  const [status, setStatus] = useState([])
  const [isActive, setIsActive] = useState(true)
  let counter = 1
  let tableData = _.reverse(_.sortBy(_.concat(deals || [], status || []), 'ctime'))

  useEffect(() => {
    let intervalId

    const fetchData = async () => {
      try {
        const res = await api.getStrategyInfo(strategyId, 100)
        if (res.orders) {
          setDeals(res.orders)
          setStatus(res.delays)
        }
      } catch (error) {
        console.error('getStrategyInfo error', error)
      }
    }

    if (isActive) {
      fetchData() // Вызываем функцию сразу при активации компонента

      intervalId = setInterval(() => {
        fetchData() // Вызываем функцию каждые 10 секунд
      }, 10000)
    }

    return () => {
      // Очищаем интервал при размонтировании компонента
      clearInterval(intervalId)
    }
  }, [strategyId, isActive])


  return (
    <>
      <div className='content'>
        <Row>
          <Col xs='12'>
            <div className='d-flex justify-content-between align-items-end mb-3 w-100'>
              <h2>{t('strategyDeals.title')}</h2>
              <Button
                className='btn-default'
                color='info'
                size='sm'
                onClick={() => {
                  navigate('/admin/strategy')
                }}>
                {t('strategyDeals.backButton')}
              </Button>
            </div>

            <Table responsive>
              <thead>
              <tr>
                <th>{t('strategyDeals.table.dealId')}</th>
                <th>{t('strategyDeals.table.direction')}</th>
                <th>{t('strategyDeals.table.amount')}</th>
                <th>{t('strategyDeals.table.price')}</th>
                <th>{t('strategyDeals.table.filled')}</th>
                <th>{t('strategyDeals.table.creationTime')}</th>
              </tr>
              </thead>
              <tbody>
              {_.map(tableData, (deal, index) => {
                const [datePart, timePart] = deal.ctime.split('T') // Разделите дату и время
                const formattedDateWithMilliseconds = `${datePart} ${timePart?.slice(0, 12)} `

                if (!deal.id) {
                  if (tableData[index + 1] && !tableData[index + 1]?.id) {
                    counter = counter + 1
                    return null
                  } else {
                    return (
                      <tr key={deal.ctime}>
                        <td colSpan={5} className={'text-center'}>
                          <span className='text-danger' style={{fontSize: 14}}>
                            {deal.status}
                          </span>
                          &nbsp;
                          <sup className='badge badge-danger'>
                            {counter}
                          </sup>
                        </td>
                        <td colSpan={1}>
                          {formattedDateWithMilliseconds}
                        </td>
                      </tr>
                    )
                  }
                }

                return (
                  <tr key={deal.deal_id + deal.ctime}>
                    <td>{deal.deal_id}</td>
                    <td>
                      {deal.side === 'BUY' ? (
                        <span className='badge badge-danger'>{deal.side}</span>
                      ) : (
                        <span className='badge badge-success'>{deal.side}</span>
                      )}
                    </td>
                    <td>{deal.amount}</td>
                    <td>{deal.price}</td>
                    <td>{deal.filled}</td>
                    <td>{formattedDateWithMilliseconds}</td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default StrategyDeals
