const initialState = {
  exchanges: [],
  // Другие данные о стратегиях
}

const exchangesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EXCHANGES':
      return {
        ...state,
        exchanges: action.payload,
      }
    // Другие обработчики действий для стратегий
    default:
      return state
  }
}

export default exchangesReducer
