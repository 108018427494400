import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import logoWhite from '../../assets/img/logo-white.svg'
import logoDark from '../../assets/img/logo.svg'
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader, Badge
} from 'reactstrap'
import LanguageSwitcher from '../LanguageSwitcher'
import { logOut } from '../../hooks'
import { ThemeContext, themes } from "contexts/ThemeContext";
import { backgroundColors } from "contexts/BackgroundColorContext";
import ThemeToggle from "../ThemeToggle";

function AdminNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [modalSearch, setModalSearch] = React.useState(false)
  const [color, setColor] = React.useState('navbar-transparent')
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem("darkMode");
    if (savedTheme !== null) {
      setIsDark(JSON.parse(savedTheme));
    }

    const handleThemeUpdate = () => {
      const updatedTheme = localStorage.getItem("darkMode");
      if (updatedTheme !== null) {
        setIsDark(JSON.parse(updatedTheme));
      }
    };

    // Подписка на событие
    window.addEventListener('themeChanged', handleThemeUpdate);

    // Отписка при удалении компонента
    return () => {
      window.removeEventListener('themeChanged', handleThemeUpdate);
    };

  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', updateColor)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', updateColor)
    }
  })

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white')
    } else {
      setColor('navbar-transparent')
    }
  }

  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor('navbar-transparent')
    } else {
      setColor('bg-white')
    }
    setCollapseOpen(!collapseOpen)
  }

  const toggleModalSearch = () => {
    setModalSearch(!modalSearch)
  }

  return (
    <>
      <Navbar className={classNames('navbar-absolute', color)} expand='lg'>
        <Container fluid>
          <div className='navbar-wrapper'>
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: props.sidebarOpened,
              })}>
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className='navbar-toggler-bar bar1' />
                <span className='navbar-toggler-bar bar2' />
                <span className='navbar-toggler-bar bar3' />
              </NavbarToggler>
            </div>
            <NavbarBrand href='/admin' onClick={e => e.preventDefault()}>
              <div className='logo-img d-flex align-items-center'>
                <img alt='logo' src={!isDark ? logoDark : logoWhite} style={{ height: '30px' }} />
                <span className='font-weight-700 ml-2'>Digital Ursa</span>
              </div>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className='ml-auto d-flex align-items-center' navbar>
              <ThemeToggle />
              <LanguageSwitcher />
              <UncontrolledDropdown nav>
                <DropdownToggle caret color='default' nav onClick={e => e.preventDefault()}>
                  <div className='photo'>
                    <img alt='...' src={require('assets/img/anime3.png')} />
                  </div>
                  <b className='caret d-none d-lg-block d-xl-block' />
                  <p className='d-lg-none'>Log out</p>
                </DropdownToggle>
                <DropdownMenu className='dropdown-navbar' right tag='ul'>
                  <NavLink tag='li'>
                    <Link to='/user/profile'>
                      <DropdownItem className='nav-item'>Profile</DropdownItem>
                    </Link>
                  </NavLink>
                  <DropdownItem divider tag='li' />
                  <NavLink tag='li'>
                    <DropdownItem className='nav-item' onClick={logOut}>
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className='separator d-lg-none' />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal modalClassName='modal-search' isOpen={modalSearch} toggle={toggleModalSearch}>
        <ModalHeader>
          <Input placeholder='SEARCH' type='text' />
          <button aria-label='Close' className='close' onClick={toggleModalSearch}>
            <i className='tim-icons icon-simple-remove' />
          </button>
        </ModalHeader>
      </Modal>
    </>
  )
}

export default AdminNavbar
