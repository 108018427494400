const initialState = {
  strategies: [],
  // strategies: [
  //   {
  //     strategy_id: 15,
  //     strategy_name: 'UBSN_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBSN',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 50000001.01,
  //       quote: 766
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 2,
  //     strategy_name: 'UBIX_CBK-USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'CBK',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 2000.14,
  //       quote: 331.8
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 18,
  //     strategy_name: 'UBWF_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBWF',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 2000.07,
  //       quote: 12400000
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 7,
  //     strategy_name: 'UBX4T_ETH',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBX4T',
  //       quote: 'ETH'
  //     },
  //     balances: {
  //       base: 1800.05,
  //       quote: 0.19
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 9,
  //     strategy_name: 'UBXE_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBXE',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 5000.13,
  //       quote: 437.7
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 8,
  //     strategy_name: 'UBX4T_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBX4T',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 0,
  //       quote: 0
  //     },
  //     active: true,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 4,
  //     strategy_name: 'UBXM_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBXM',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 6000.09,
  //       quote: 14334000
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 12,
  //     strategy_name: 'UBPAY_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBPAY',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 6325.13,
  //       quote: 1263.297215
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 16,
  //     strategy_name: 'UBCDR_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBCDR',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 4000,
  //       quote: 32000000
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 3,
  //     strategy_name: 'UBX_ETH',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'ETH'
  //     },
  //     balances: {
  //       base: 25000001.02,
  //       quote: 0.309
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 5,
  //     strategy_name: 'UBXE_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBXE',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 8001,
  //       quote: 16000000
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 11,
  //     strategy_name: 'UBXM_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBXM',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 13000.07,
  //       quote: 730.01
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Strong imbalance'
  //   },
  //   {
  //     strategy_id: 13,
  //     strategy_name: 'UBWF_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBWF',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 4820.07,
  //       quote: 1234.018635
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 6,
  //     strategy_name: 'UBSN_ETH',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBSN',
  //       quote: 'ETH'
  //     },
  //     balances: {
  //       base: 53710575.02,
  //       quote: 0.27
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Strong imbalance'
  //   },
  //   {
  //     strategy_id: 1,
  //     strategy_name: 'UBX-USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 0,
  //       quote: 0
  //     },
  //     active: true,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 10,
  //     strategy_name: 'UBCDR_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBCDR',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 5000.06,
  //       quote: 990.04
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Strong imbalance'
  //   },
  //   {
  //     strategy_id: 14,
  //     strategy_name: 'UBX4T_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBX4T',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 8000.04,
  //       quote: 1240.05
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: 'Strong imbalance'
  //   },
  //   {
  //     strategy_id: 17,
  //     strategy_name: 'UBPAY_UBX',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Ubix',
  //     pair: {
  //       base: 'UBPAY',
  //       quote: 'UBX'
  //     },
  //     balances: {
  //       base: 1500.03,
  //       quote: 13821000
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 27,
  //     strategy_name: 'BITRUE-UBX-USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Bitrue',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 900006,
  //       quote: 32
  //     },
  //     active: true,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       1068.77485977,
  //       2121.474876,
  //       4909.160655029999,
  //       87.53788800000001,
  //       2070.9907347900003,
  //       0,
  //       881.54352,
  //       296.46515108,
  //       2375.980686,
  //       2903.54544,
  //       3321.3444012,
  //       420.043158,
  //       5321.70353488,
  //       1443.2321249999998,
  //       1305.6276389,
  //       2376.4430340000004,
  //       1750.4366850000001,
  //       8096.75129526,
  //       3557.6242033200006,
  //       2448.69295411,
  //       6180.591006000001,
  //       4810.486525419999,
  //       7185.115297939999,
  //       4044.4421340400004,
  //       2748.181987,
  //       7463.334300000001,
  //       9990.978400000002
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 28,
  //     strategy_name: 'BITRUE-UBSN-USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Bitrue',
  //     pair: {
  //       base: 'UBSN',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 2300003,
  //       quote: 23
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       380.814588,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       380.814588,
  //       0,
  //       0,
  //       0,
  //       0,
  //       1556.3253814000002,
  //       0,
  //       3921.6540192600005,
  //       1849.0218337,
  //       0,
  //       0,
  //       246.898573,
  //       22.474368,
  //       0,
  //       0,
  //       0,
  //       837.6709971999999,
  //       4129.60207396,
  //       1673.337096,
  //       807.104052,
  //       0,
  //       1013.949618,
  //       0,
  //       218.61228,
  //       327.55053599999997,
  //       623.00598,
  //       3793.1100088800004,
  //       0,
  //       0,
  //       2283.6396
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 24,
  //     strategy_name: 'MEXC-UBX-USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Mexc',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 3500000.04,
  //       quote: 80.04
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       568.84987488,
  //       796.185923,
  //       705.10183176,
  //       439.79821066,
  //       815.50514186,
  //       591.2968583,
  //       420.47860114,
  //       788.77927413,
  //       1061.2669401,
  //       1228.98999774
  //     ],
  //     week_vol: [
  //       0,
  //       17355.21877782,
  //       24789.748760870003,
  //       34473.42182706,
  //       69418.7280837,
  //       57538.594090909995,
  //       39472.90842878,
  //       93372.7996909,
  //       56212.22538477999,
  //       52431.127727650004,
  //       72032.33184291,
  //       92365.83337670998,
  //       89244.53228514001,
  //       146298.50884964,
  //       104653.74738217001,
  //       147410.58584434,
  //       71147.43701478001,
  //       68926.464816,
  //       94977.58470135002,
  //       96288.22888439,
  //       44822.673510459994,
  //       112177.85748653003,
  //       189788.41456853997,
  //       101262.92269841999,
  //       307940.33671665005,
  //       355097.22801324,
  //       285000.00680846005,
  //       132433.41327861,
  //       425443.94980283995,
  //       280807.53075826995
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 26,
  //     strategy_name: 'PROBIT_UBX_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Probit',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 1717914.4398,
  //       quote: 7.02025895
  //     },
  //     active: true,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       783.39106436,
  //       1513.51369994,
  //       1561.19279808
  //     ],
  //     week_vol: [
  //       0,
  //       7983.0189595,
  //       19163.15682235,
  //       14001.746883689999,
  //       7879.62325821,
  //       7871.09678411,
  //       10602.14449393,
  //       20343.01421092,
  //       0,
  //       11168.2522069,
  //       24974.122198579997,
  //       5241.54116429,
  //       21312.96251697,
  //       10040.99420487,
  //       14412.29661714,
  //       9382.9560482,
  //       12520.26938133,
  //       9767.586082869999,
  //       8583.00824724,
  //       8574.3775492,
  //       22808.45688051,
  //       5670.175051,
  //       9702.908658150001,
  //       8354.599536310001,
  //       4406.96358755,
  //       13331.64848324,
  //       3048.3454187999996,
  //       6307.934746999999,
  //       4065.79203129,
  //       1513.04343537
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 35,
  //     strategy_name: 'MEXC_BIG_UBX_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Mexc',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 4500000.12,
  //       quote: 100.12
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       291.65844743,
  //       955.59601014,
  //       153.20899746,
  //       221.09642964,
  //       157.10225232,
  //       107.17896619,
  //       12.19682033,
  //       648.90336376,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       15571.43368701,
  //       85853.22934255,
  //       24888.33371188,
  //       108017.36708815001,
  //       101631.66594461001,
  //       53537.49224463,
  //       76546.57720836998,
  //       105473.43885825,
  //       134643.23757706,
  //       177572.61080594003,
  //       157628.88665737,
  //       83632.89769505001,
  //       292600.96522907994,
  //       249293.59462330997,
  //       340498.46782455995,
  //       340058.03912749,
  //       332364.85326899,
  //       327875.45389391994,
  //       159232.08014248,
  //       108603.43323342998,
  //       108637.67767885998,
  //       118144.73680956998,
  //       151799.57200761,
  //       131474.96312782998,
  //       109348.28427304998,
  //       92763.52287784999,
  //       82655.95522639998,
  //       94597.05188944998,
  //       111855.52646828
  //     ],
  //     status: 'Strong imbalance'
  //   },
  //   {
  //     strategy_id: 25,
  //     strategy_name: 'MEXC_UBSN_USDT',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Mexc',
  //     pair: {
  //       base: 'UBSN',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 4000000,
  //       quote: 42
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       192.32015558,
  //       34.38683993,
  //       149.98739015,
  //       0,
  //       0,
  //       0,
  //       420.55857496,
  //       2411.99856221,
  //       2367.67468636
  //     ],
  //     week_vol: [
  //       0,
  //       39396.75381557999,
  //       57713.73958085,
  //       26621.152686819994,
  //       39200.11071116,
  //       27509.82608348,
  //       55590.13648719,
  //       51949.73831969999,
  //       39036.623282910004,
  //       55890.77031732,
  //       70423.75944708001,
  //       85400.75361214,
  //       77167.72065228,
  //       87251.15596838998,
  //       79387.87908713,
  //       89662.77948328001,
  //       92574.89880028002,
  //       77440.18530587999,
  //       95257.88117046,
  //       105992.13697387,
  //       110485.42154737998,
  //       100846.03759203,
  //       104141.89908055002,
  //       111924.13651545,
  //       109034.70558642,
  //       97494.62765741999,
  //       92864.40787545001,
  //       82803.86908368001,
  //       101130.48463762998,
  //       103717.21547538
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 29,
  //     strategy_name: 'Bitmart',
  //     strategies_type: 'VolSupportKucoinSingle',
  //     exchange: 'Bitmart',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 1100000,
  //       quote: 25
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       353.3678928,
  //       1662.8231442,
  //       1657.9888152,
  //       1735.4344074,
  //       1559.2135314,
  //       1963.5427344,
  //       129.5389488,
  //       197.6054649,
  //       1239.7650024,
  //       2678.7587682,
  //       219.6160785,
  //       0
  //     ],
  //     week_vol: [
  //       2016.1910369999998,
  //       20259.342217499994,
  //       30697.9018356,
  //       16652.7396927,
  //       29673.007851900005,
  //       26193.904985099998,
  //       20378.2211532,
  //       16829.5101933,
  //       28541.051427000002,
  //       28999.5425451,
  //       34160.1993954,
  //       16223.9738607,
  //       36452.11160580001,
  //       26562.726929400003,
  //       9747.4853577,
  //       7793.511911100001,
  //       15142.6761378,
  //       13195.1330763,
  //       12332.220552300001,
  //       15362.427948600001,
  //       16393.7640159,
  //       6963.1452303,
  //       41034.14562329999,
  //       32168.0291202,
  //       18652.560930899996,
  //       32652.5158086,
  //       11235.0718395,
  //       13674.7126791,
  //       15523.776639299998,
  //       6679.874586600001
  //     ],
  //     status: 'Balances low'
  //   },
  //   {
  //     strategy_id: 30,
  //     strategy_name: 'PROBIT_UBSN_USDT',
  //     strategies_type: 'VolSupportKucoinSlave',
  //     exchange: 'Probit',
  //     pair: {
  //       base: 'UBSN',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 0,
  //       quote: 0
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 36,
  //     strategy_name: 'BITRUE-UBX-USDT-Liq',
  //     strategies_type: 'BuyingUpLiquidity',
  //     exchange: 'Bitrue',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 19155607.41,
  //       quote: 372.8876224
  //     },
  //     active: true,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       399.46472256,
  //       0,
  //       135.49645504,
  //       0,
  //       1590.00745856,
  //       553.0944004,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       399.46472256,
  //       2278.598314,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       2767.64341336,
  //       483.46527265,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 44,
  //     strategy_name: 'MEXC_LiQUID_UBX_USDT',
  //     strategies_type: 'BuyingUpLiquidity',
  //     exchange: 'Mexc',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 25000000.06,
  //       quote: 300.01
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       2628.32101185,
  //       3252.4507981700003,
  //       1291.30164924,
  //       2127.49639862,
  //       1548.07749451,
  //       4258.11322019,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       1818.96725299,
  //       3942.28288076,
  //       0,
  //       17587.617595269996,
  //       4731.92567272,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   },
  //   {
  //     strategy_id: 45,
  //     strategy_name: 'Antibot_test',
  //     strategies_type: 'AntibotKucoin',
  //     exchange: 'Mexc',
  //     pair: {
  //       base: 'UBX',
  //       quote: 'USDT'
  //     },
  //     balances: {
  //       base: 51349225,
  //       quote: 300
  //     },
  //     active: false,
  //     '24h_vol': [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     week_vol: [
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0,
  //       0
  //     ],
  //     status: ''
  //   }
  // ],
  types: [],
  lastUpdated: ''
  // Другие данные о стратегиях
}

const strategyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STRATEGIES':
      return {
        ...state,
        strategies: [...state.strategies, ...action.payload],
        lastUpdated: new Date().getTime()
      };
    case 'UPDATE_STRATEGIES':
      return {
        ...state,
        strategies: [...action.payload],
        lastUpdated: new Date().getTime()
      };
    case 'SET_STRATEGIES_TYPES':
      return {
        ...state,
        types: action.payload,
      }
    // Другие обработчики действий для стратегий
    default:
      return state
  }
}

export default strategyReducer
