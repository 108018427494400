export const setStrategies = strategies => {
  return {
    type: 'SET_STRATEGIES',
    payload: strategies,
  }
}
export const updateStrategiesRedux = strategies => {
  return {
    type: 'UPDATE_STRATEGIES',
    payload: strategies,
  }
}
export const setStrategiesTypes = types => {
  return {
    type: 'SET_STRATEGIES_TYPES',
    payload: types,
  }
}
