import {Col, Row} from "reactstrap";
import React from "react";
import DealForm from "./DealForm";
import {useLocation} from "react-router-dom";

const DealsForms = () => {
  const location = useLocation()

  return (
    <div className='content dealsForm'>
      <Row>
        <Col xl={6}>
          <DealForm buy={false} state={location?.state}/>
        </Col>
        <Col xl={6}>
          <DealForm buy={true} state={location?.state}/>
        </Col>
      </Row>
    </div>
  )
}
export default DealsForms