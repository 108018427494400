import React from 'react'
import { Line } from 'react-chartjs-2'

const Chart = ({ values, backgroundColor }) => {
  const data = canvas => {
    const ctx = canvas.getContext('2d')

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50)

    function hexToRgba(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16) || 255
      const g = parseInt(hex.slice(3, 5), 16) || 255
      const b = parseInt(hex.slice(5, 7), 16) || 255
      return `rgba(${r},${g},${b},${alpha})`
    }

    gradientStroke.addColorStop(1, hexToRgba(backgroundColor, 0.2))
    gradientStroke.addColorStop(0.4, hexToRgba(backgroundColor, 0.0))
    gradientStroke.addColorStop(0, hexToRgba(backgroundColor, 0))

    const createHourLabels = () => {
      const currentHour = new Date().getHours();
      let labels = [];

      for (let i = 0; i < 24; i++) {
        let hour = (currentHour + i) % 24; // Обеспечиваем цикличность (после 23 идет 0)
        labels.push(`${hour < 10 ? '0' : ''}${hour}:00`); // Форматируем часы в формате "00:00"
      }

      return labels;
    }
    return {
      labels: createHourLabels(),
      datasets: [
        {
          label: '',
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: backgroundColor,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: backgroundColor,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: values,
        },
      ],
    }
  }
  let options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },

    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest',
    },
    responsive: true,
    scales: {
      // yAxes: {
      //   barPercentage: 1.6,
      //   gridLines: {
      //     drawBorder: false,
      //     color: 'rgba(29,140,248,0.0)',
      //     zeroLineColor: 'transparent',
      //   },
      //   ticks: {
      //     suggestedMin: 60,
      //     suggestedMax: 125,
      //     padding: 20,
      //     fontColor: '#9a9a9a',
      //   },
      // },
      // xAxes: {
      //   barPercentage: 1.6,
      //   gridLines: {
      //     drawBorder: false,
      //     color: 'rgba(29,140,248,0.1)',
      //     zeroLineColor: 'transparent',
      //   },
      //   ticks: {
      //     padding: 20,
      //     fontColor: '#9a9a9a',
      //   },
      // },
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  }

  return <Line data={data} options={options} />
}
export default Chart
