import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button } from 'reactstrap'
import { clearError, clearSuccess } from '../../redux/actions/alertActions'
import { CSSTransition } from 'react-transition-group'
import './style.css'

const AlertComponent = () => {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    // Отображать алерт при наличии ошибки
    setShowAlert(!!alert.error || !!alert.success)
    if (!!alert.error || !!alert.success) {
      setTimeout(() => {
        handleCloseAlert()
      }, 5000)
    }
  }, [alert])

  const handleCloseAlert = () => {
    dispatch(clearSuccess())
    dispatch(clearError())
    setShowAlert(false)
  }

  return (
    <CSSTransition
      in={showAlert}
      timeout={300} // Продолжительность анимации
      classNames='alert'
      unmountOnExit>
      <div>
        {alert.error && (
          <div className='error-alert'>
            <Alert color={'danger'}>
              {alert.error}
              <Button close onClick={handleCloseAlert} />
            </Alert>
          </div>
        )}
        {alert.success && (
          <div className='error-alert'>
            <Alert color={'success'}>
              {alert.success}
              <Button close onClick={handleCloseAlert} />
            </Alert>
          </div>
        )}
      </div>
    </CSSTransition>
  )
}

export default AlertComponent
