import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import strategyReducer from './reducers/strategyReducer'
import alertReducer from './reducers/alertReducer'
import exchangesReducer from './reducers/exchangesReducer'
import usersReducer from './reducers/usersReducer'
import keysSetsReducer from './reducers/keysSetsReducer'

const rootReducer = combineReducers({
  strategy: strategyReducer,
  keysSets: keysSetsReducer,
  users: usersReducer,
  exchanges: exchangesReducer,
  alert: alertReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
